import { ImmerReducer, createActionCreators } from "immer-reducer";
import { State } from "./state";

export class SearchReducer extends ImmerReducer<State> {
    // Force custom class for IE11 support. For some reason in some
    // transpilation setups the class name becomes undefined
    static customName = "SearchReducer";

    setStatus(nextStatus: State["status"]) {
        this.draftState.status = nextStatus;
    }

    setError(nextError: State["error"]) {
        this.draftState.error = nextError;
    }

    resetResults() {
        this.draftState.results = {};
    }

    setAllResults(res: State["results"]) {
        this.draftState.results = res;
    }

    addAllResults(res: State["results"]) {
        for (const key in res) {
            const more = res[key];

            let existing = this.draftState.results[key];
            if (!existing) {
                existing = {
                    hits: [],
                    total: more?.total ?? 0,
                };
            }

            existing.hits.push(...(more?.hits ?? []));
        }
    }
}

export const SearchActions = createActionCreators(SearchReducer);
