import React from "react";
import { ValuSearchEventListener, ValuSearchEvents } from "./events";
import { FocusTrap } from "@valu/focus-trap";
import { SearchEngine } from "./redux/search-engine";
import { CustomFields } from "@findkit/fetch";
import { OrderedGroups } from "./components/GroupInfo";
import { SearchResult } from "./redux/state";

export interface SlotRenderer<Params = {}> {
    (context: {
        params: Params;
        original: React.ReactNode;
        /**
         * @deprecated
         */
        config: FullValuSearchConfig;
    }): any;
}

export interface UIStrings {
    searchResultsTitle: string;
    moreResults: string;
    moreResultsIn: string;
    allShown: string;
    noResults: string;
    back: string;
    close: string;
    closeSearch: string;
    searchInput: string;
    searchTerm: string;
    searchResults: string;
    searchResultGroup: string;
    searchResultDate: string;
    searchResultExcerpt: string;
    searchInstructions: string;
}

export interface GroupFilters {
    /**
     * Queries search results that match the tag query
     * [[tagA, tagB]] tagA OR tagB
     * [[tagA], [tagB]] tagA AND tagB
     */
    tagQuery: string[][];
    /**
     * 0-1 numerical value for demoting old pages
     */
    createdDecay?: number;
    /**
     * 0-1 numerical value for demoting stagnant pages
     */
    modifiedDecay?: number;
    /**
     * String type time expression
     * Used with createdDecay or modifiedDecay
     * Defines in which timeframe decay filter is applied, e.g. "7d"
     */
    decayScale?: string;
    /**
     * Defines highlight length
     * Use 0 for blocking highlight query (performance boost)
     */
    highlightLength?: number;
}

/**
 * Group type for Reach rendering
 */
export interface ReactGroup {
    id: string;
    title: string;
    scoreBoost: number;
    filters: GroupFilters;
}

/**
 * Group for the public interface
 */
export interface Group {
    /**
     * @deprecated
     */
    tagGroupId?: string;
    id?: string;
    title: string;
    scoreBoost?: number;
    filters: GroupFilters;
}

/**
 * Group type for the search engine
 */
export interface EngineGroup {
    id: string;
    scoreBoost: number;
    filters: GroupFilters;
    size: number;
}

export interface Slots {
    /**
     * Wraps the full screen header
     */
    fullScreenHeader?: SlotRenderer<{}>;
    /**
     * Wraps the full screen header
     */
    modalHeader?: SlotRenderer<{}>;
    /**
     * Wraps the close button located at top right corner
     */
    closeButton?: SlotRenderer<{
        close: VoidFunction;
    }>;
    /**
     * Wraps the whole group in groupped preview view
     */
    groupPreview?: SlotRenderer<{
        title: string;
        total: number;
        hits: SearchResult[];
    }>;
    /**
     * Wraps group title
     */
    groupTitle?: SlotRenderer<{
        id: string;
        title: string;
        ariaLabel: string;
    }>;
    /**
     * Wraps group total search results
     */
    groupTotal?: SlotRenderer<{
        id: string;
        total: string;
        ariaLabel: string;
    }>;
    /**
     * Wraps individual hits
     */
    hit?: SlotRenderer<{
        /**
         * @deprecated use "id" instead
         */
        tagGroupId: string;
        id: string;
        highlight: string;
        created: Date;
        modified: Date;
        title: string;
        url: string;
        score: number;
        customFields: CustomFields;
    }>;
    hitInner?: SlotRenderer<{
        id: string;
        highlight: string;
        created: Date;
        modified: Date;
        title: string;
        url: string;
        score: number;
        customFields: CustomFields;
    }>;
    /**
     * Wraps "show more results" | "all results shown" | "no search results"
     */
    groupLinkTitleWrap?: SlotRenderer<{
        ariaLabel: string;
        groupTitle: string;
        type: "more" | "allShown" | "noResults";
    }>;
    /**
     * Wraps group navigation bar
     */
    groupNav?: SlotRenderer<{
        groups: { title: string; id: string; total: number }[];
    }>;
    /**
     * Wraps the whole Valu Search UI
     */
    mainWrap?: SlotRenderer<{}>;
    /**
     * Wraps the result groups on the Valu Search starting UI
     */
    resultsWrap?: SlotRenderer<{
        isDetails?: boolean;
        orderedGroups?: OrderedGroups[];
    }>;
    /**
     * Wraps the detailed group results
     */
    groupResultsWrap?: SlotRenderer<{}>;
    /**
     * Wraps the detailed groups results header
     */
    groupResultsHeaderWrap?: SlotRenderer<{}>;
    /**
     * Wraps the Valu Search Ad
     */
    valuSearchAd?: SlotRenderer<{}>;
    /**
     *
     */
    searchMore?: SlotRenderer<{
        searchMore: () => void;
        hasMore: boolean;
    }>;
}

export interface FullValuSearchConfig {
    searchEndpoint: string;
    apiKey: string;
    /**
     * Defines group order in UI,
     * default option is "score"
     */
    orderBy: "tagName" | "total" | "score" | "originalNotEmpties" | "original";

    /**
     * Set to false to disable focus trapping
     */
    focusTrap: boolean;
    validateTabbable?: (
        tabbables: HTMLElement,
        container: HTMLElement,
        trap: FocusTrap,
    ) => boolean;
    onEvent?: ValuSearchEventListener;
    tagGroups: ReactGroup[];
    /** @deprecated use uiLang and searchLang instead */
    lang?: string;
    uiLang?: string;
    searchLang?: string[] | undefined;
    instanceId: string;
    slots: Slots;
    previewGroupSize?: number;
    minSearchTermsLength?: number;
    searchMoreSize?: number;
    searchThrottleTime?: number;
    infiniteScroll: boolean;
}

export type ValuSearchConfigContextType = {
    // config: ValuSearchConfig;
    instanceId: string;
    focusTrap: boolean;
    uiStrings: UIStrings;
    infiniteScroll: boolean;
    lang: FullValuSearchConfig["lang"];
    uiLang: FullValuSearchConfig["uiLang"];
    searchLang: FullValuSearchConfig["searchLang"];
    tagGroups: FullValuSearchConfig["tagGroups"];
    validateTabbable: FullValuSearchConfig["validateTabbable"];
    minSearchTermsLength: FullValuSearchConfig["minSearchTermsLength"];
    apiKey: FullValuSearchConfig["apiKey"];
    searchEndpoint: FullValuSearchConfig["searchEndpoint"];
    previewGroupSize: FullValuSearchConfig["previewGroupSize"];
    slots: FullValuSearchConfig["slots"];
    orderBy: FullValuSearchConfig["orderBy"];
    _config: FullValuSearchConfig;
    inputs: HTMLInputElement[];

    /**
     * @deprecated use .useInput() instead
     */
    useValuSearchInputConnect: () => React.MutableRefObject<HTMLInputElement | null>;
    deactivate: () => void;
    animationFinished: boolean;
    setAnimationFinished: (animationFinished: boolean) => void;
    grouppedPreviewLoaded: boolean;
    setGrouppedPreviewLoaded: (grouppedPreviewLoaded: boolean) => void;
    events: ValuSearchEvents;
} & SearchEngine["api"];

export const ValuSearchConfigContext =
    React.createContext<ValuSearchConfigContextType | null>(null);

export function useValuSearch() {
    const context = React.useContext(ValuSearchConfigContext);

    if (!context) {
        throw new Error("No ValuSearchConfigContext found");
    }

    return context;
}

/**
 * @deprecated use useValuSearch instead
 */
export function useConfig() {
    return useValuSearch();
}
