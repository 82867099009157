import { useQueryValue } from "./query-context";
import { useValuSearch } from "../config";
import { useParseSearchTerms } from "./helpers";

export function useSearchTerms() {
    const hash = useQueryValue();
    const parseSearchTerms = useParseSearchTerms();

    if (!hash) {
        return "";
    }

    return parseSearchTerms(hash);
}

export function useIsSearchActive() {
    const query = useQueryValue();
    const instanceId = useValuSearch().instanceId;
    return query.has(instanceId + "_q");
}
