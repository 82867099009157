import { configureStore } from "@epeli/redux-stack";
import { createReducerFunction } from "immer-reducer";

import { initialState } from "./state";
import { SearchReducer } from "./actions";

export function createSearchStore() {
    const reducer = createReducerFunction(SearchReducer);

    const store = configureStore({
        preloadedState: initialState,
        reducers: [reducer],
    });

    return store;
}
