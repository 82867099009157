import React, { useState, useEffect } from "react";
import { css } from "react-bemed/css";
import { useSearchResults } from "../redux/hooks";
import { useValuSearch } from "../config";
import { Slot } from "./Slot";

import { bemed } from "./bemed";
import { GroupResults } from "./GroupResults";
import { GrouppedResultPreview } from "./GrouppedResultPreview";
import { useSearchTerms } from "../utils/use-search-terms";
import { usePreviousValue, u } from "../utils/helpers";
import { useGroup, useIsGroupDetails, useOrderedGroups } from "./GroupInfo";

const Blk = bemed({
    name: "ResultsResolver",

    css: css`
        width: 100%;
        backface-visibility: hidden;
        transform: translateZ(0);
        -webkit-font-smoothing: subpixel-antialiased;
        padding-bottom: 32px;
    `,
});

// off screen element per https://webaim.org/techniques/css/invisiblecontent/#techniques
// instruction for screen reader content
const SRContainerBlk = bemed({
    name: "SRContainer",
    as: "div",

    css: css`
        position: absolute;
        left: ${u(-10000)};
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
    `,

    elements: {
        AsH1: bemed({
            as: "h1",
            css: css`
                position: absolute;
                left: ${u(-10000)};
                top: auto;
                width: 1px;
                height: 1px;
                overflow: hidden;
            `,
        }),
    },
});

function SearchResultsMainTitle() {
    const { uiStrings, instanceId } = useValuSearch();
    return (
        <SRContainerBlk.AsH1
            id="vs-searchResultHeader"
            className={`valu-search-main-title-${instanceId}`}
        >
            {uiStrings.searchResultsTitle}
        </SRContainerBlk.AsH1>
    );
}

function SearchInstructions() {
    const { uiStrings, instanceId } = useValuSearch();
    return (
        <>
            <SRContainerBlk id={`valu-search-instructions-${instanceId}`}>
                {uiStrings.searchInstructions}
            </SRContainerBlk>
            <SRContainerBlk
                id={`valu-search-excerpt-information-${instanceId}`}
            >
                {uiStrings.searchResultExcerpt}
            </SRContainerBlk>
        </>
    );
}

function ScreenReaderContainer() {
    const searchResults = useSearchResults();
    const searchTerms = useSearchTerms();
    const prevSearchTerm = usePreviousValue(searchTerms);
    const { uiStrings, tagGroups, instanceId } = useValuSearch();
    const group = useGroup();

    const SR_DEBOUNCE_TIME = 400;

    const [srMessage, setSRMessage] = useState("");

    const searchResultTotal = Object.values(searchResults).reduce(
        (sum, res) => sum + res.total,
        0,
    );

    // debounced sr message hook
    useEffect(() => {
        //do not read this when navigating between groups
        if (searchTerms === prevSearchTerm) {
            return;
        }
        const timer = setTimeout(
            () =>
                setSRMessage(
                    `${uiStrings.searchTerm}: ${searchTerms}. ${uiStrings.searchResults}: ${searchResultTotal}`,
                ),
            SR_DEBOUNCE_TIME,
        );

        return () => clearTimeout(timer);
    }, [
        searchResults,
        searchTerms,
        searchResultTotal,
        uiStrings,
        prevSearchTerm,
    ]);

    // immediate sr message hook
    useEffect(() => {
        if (!group) {
            const timer = setTimeout(
                () =>
                    setSRMessage(
                        `${uiStrings.searchTerm}: ${searchTerms}. ${uiStrings.searchResults}: ${searchResultTotal}`,
                    ),
                SR_DEBOUNCE_TIME,
            );

            return () => clearTimeout(timer);
        }

        if (group) {
            const total = searchResults[group.id]?.total || 0;
            setSRMessage(
                `${uiStrings.searchResultGroup}: ${group.title}. ${uiStrings.searchResults}: ${total}`,
            );
        }
    }, [
        searchResultTotal,
        uiStrings,
        searchResults,
        searchTerms,
        tagGroups,
        group,
    ]);

    return (
        <SRContainerBlk
            aria-live="polite"
            data-testid="sr-live-messages"
            id={`valu-search-screen-reader-live-messages-${instanceId}`}
        >
            {srMessage}
        </SRContainerBlk>
    );
}

export function SearchResultsViewer() {
    const { instanceId } = useValuSearch();
    const isDetails = useIsGroupDetails();
    const orderedGroups = useOrderedGroups();

    return (
        <Blk className={`valu-search-results-viewer-${instanceId}`}>
            <SearchResultsMainTitle />
            <SearchInstructions />
            <ScreenReaderContainer />
            <Slot
                name="groupNav"
                params={{
                    groups: orderedGroups.map((group) => {
                        return {
                            title: group.title,
                            id: group.id,
                            total: group.groupResults.total,
                        };
                    }),
                }}
            ></Slot>
            <Slot
                name="resultsWrap"
                params={{
                    isDetails,
                    orderedGroups,
                }}
            >
                {isDetails ? <GroupResults /> : <GrouppedResultPreview />}
            </Slot>
        </Blk>
    );
}
