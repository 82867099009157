import { CustomFields } from "@findkit/fetch";

export interface SearchResult {
    id: string;
    created: Date;
    modified: Date;
    score: number;
    title: string;
    url: string;
    highlight: string;
    customFields: CustomFields;
}

export interface ResultsWithTotal {
    tagGroupId: string;
    id: string;
    hits: SearchResult[];
    duration?: number;
    total: number;
}

export interface State {
    status: "initial" | "fetching" | "ready";
    error: {
        source: "fetch" | "other";
        message: string;
    } | null;

    results: {
        [groupId: string]: {
            hits: SearchResult[];
            total: number;
            duration?: number;
        };
    };
}

export const initialState: State = {
    status: "initial",
    error: null,
    results: {},
};
