import {
    FullScreenInputWithCloseButton,
    FullScreenLogoWrapper,
    ValuSearch,
} from "@valu/react-valu-search";
import React from "react";

// Load customer specific style customizations
import "./styles.css";

//Define allowed languages on page here
const allowedLanguages = ["fi", "en"] as const;
type AllowedLanguages = typeof allowedLanguages[number];

function isAllowedLanguage(lang: string): lang is AllowedLanguages {
    return (allowedLanguages as readonly string[]).indexOf(lang) !== -1;
}

function getHTMLLang(): AllowedLanguages {
    const lang = document
        .getElementsByTagName("html")[0]
        .lang.slice(0, 2)
        .toLowerCase();

    // defaults translations to english, change this to something else in allowedLanguages array if wanted
    if (!isAllowedLanguage(lang)) {
        return "en";
    }

    return lang;
}

const lang = getHTMLLang();

// Define translations for each supported language
const tagGroupTitles = {
    posts: {
        fi: "Ajankohtaiset",
        en: "Posts",
    },
    pages: {
        fi: "Sivut",
        en: "Pages",
    },
    pdf: {
        fi: "PDF-tiedostot",
        en: "PDF-files",
    },
    contacts: {
        fi: "Yhteystiedot",
        en: "Contacts",
    },
    blogs: {
        fi: "Blogikirjoitukset",
        en: "Blog articles",
    },
    tourist_attractions: {
        fi: "Matkailukohteet",
        en: "Tourist attractions",
    },
    otherSites: {
        fi: "Tulokset muilta sivustoilta",
        en: "Search results from other sites",
    },
};

interface TagGroup {
    title: string;
    scoreBoost?: number;
    filters: {
        tagQuery: string[][];
        createdDecay?: number;
        decayScale?: string;
    };
}

interface ByDomain {
    [domain: string]: {
        tagGroups: TagGroup[];
    };
}

const byDomain: ByDomain = {
    // SINGLE DOMAIN IN INDEX
    "sastamala.valudata.fi": {
        // If there is only a single domain in search index,
        // this tagGroups[] could be passed directly to renderMultiSearchResults()
        tagGroups: [
            {
                title: tagGroupTitles.posts[lang],
                // multiplies each score by 10
                // making results in this group more relevant
                scoreBoost: 10,
                filters: {
                    // making old posts less relevant
                    createdDecay: 0.5,
                    // specifying custom decay scale, 14d old results get the above multiplier
                    decayScale: "14d",
                    tagQuery: [["wp_post_type/post"]],
                },
            },
            {
                title: tagGroupTitles.pages[lang],
                filters: {
                    tagQuery: [["wp_post_type/page"]],
                },
            },
            {
                title: tagGroupTitles.contacts[lang],
                filters: {
                    tagQuery: [["wp_post_type/contact"]],
                },
            },
            {
                title: tagGroupTitles.blogs[lang],
                filters: {
                    tagQuery: [["wp_post_type/blog"]],
                },
            },
            {
                scoreBoost: 0.001,
                title: tagGroupTitles.pdf[lang],
                filters: {
                    tagQuery: [
                        ["pdf"],
                        ["domain/sastamala.valudata.fi"]
                    ],
                },
            },
            {
                title: tagGroupTitles.otherSites[lang],
                scoreBoost: 0.001,
                filters: {
                    tagQuery: [
                        [
                            "domain/visitsastamala.fi",
                            "domain/sotesi.fi",
                        ]
                    ],
                },
            },
        ],
    },
    "www.visitsastamala.fi": {
        tagGroups: [
            {
                title: tagGroupTitles.pages[lang],
                filters: {
                    tagQuery: [
                        ["wp_post_type/page"],
                        ["domain/www.visitsastamala.fi"],
                    ],
                },
            },
            {
                title: tagGroupTitles.tourist_attractions[lang],
                filters: {
                    tagQuery: [
                        ["wp_post_type/datahub-product"],
                        ["domain/www.visitsastamala.fi"],
                    ],
                },
            },
            {
                scoreBoost: 0.001,
                title: tagGroupTitles.pdf[lang],
                filters: {
                    tagQuery: [
                        ["pdf"],
                        ["domain/www.visitsastamala.fi"]
                    ],
                },
            },
        ],
    },
};

let domain = location.hostname;

const devDomainRegexp = RegExp(/.*-(?:.*).test/);

if (devDomainRegexp.test(domain)) {
    const devDomainPartRegex = RegExp(/(.*)-(.*).test/g);
    const domainMatches = devDomainPartRegex.exec(domain);

    if (!domainMatches) {
        console.log("Tried to load Valu Search on unrecognized dev domain");
    }

    if (domainMatches !== null && domainMatches[2] !== null) {
        domain = domainMatches[1] + "." + domainMatches[2];
    }
} else if (domain === "localhost" || domain === "cdn.search.valu.pro") {
    domain = "";
}

if (!byDomain[domain]) {
    console.log("Tried to load Valu Search on unrecognized domain. Using default search for Sastamala");
    domain = "sastamala.valudata.fi";
}

let logoUrl = "https://cdn.search.valu.pro/sastamala/files/logo-sastamala.svg";
let visitLogoUrl = "https://cdn.search.valu.pro/sastamala/files/logo-visitsastamala.svg";

if (domain === "www.visitsastamala.fi") {
    logoUrl = visitLogoUrl;
}

const vs = new ValuSearch({
    customer: "sastamala",
    apiKey: "ca5742278d79da1bb687b7e268aaf4cd1cf55d7e",
    uiLang: lang,
    searchLang: [lang],
    slots: {
        // usually we want a logo with the full screen input,
        // if the installation does not need one, this can be removed
        //
        // Add customer logo to src/files/ and reference it here
        modalHeader() {
            return (
                <>
                    <FullScreenLogoWrapper
                        href={logoUrl}
                        alt={"Sastamala logo"}
                    />
                    <FullScreenInputWithCloseButton />
                </>
            );
        },
    },
    groups: byDomain[domain].tagGroups,
});

export default vs;
