import React, { useContext } from "react";
import { SearchResult } from "../redux/state";
import { assertNonNullable } from "../utils/helpers";

const HitContext = React.createContext<SearchResult | undefined>(undefined);

export function HitContextProvider(props: {
    children: React.ReactNode;
    hit: SearchResult;
}) {
    return (
        <HitContext.Provider value={props.hit}>
            {props.children}
        </HitContext.Provider>
    );
}

export function useHit() {
    const hit = useContext(HitContext);
    assertNonNullable(
        hit,
        "Could not find HitContext. The component should be used only from the 'hit' slots",
    );
    return hit;
}
