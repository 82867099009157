import React, { useContext } from "react";
import { ReactGroup, useValuSearch } from "../config";
import { useSearchResults } from "../redux/hooks";
import { SearchResult } from "../redux/state";
import { useQueryValue } from "../utils/query-context";

const GroupIdContext = React.createContext<string | undefined>(undefined);

export function GroupIdProvider(props: {
    children: React.ReactNode;
    id: string;
}) {
    return (
        <GroupIdContext.Provider value={props.id}>
            {props.children}
        </GroupIdContext.Provider>
    );
}

export function useHasSingleGroup() {
    const { tagGroups } = useValuSearch();
    return tagGroups.length === 1;
}

export function useIsGroupDetails() {
    const query = useQueryValue();
    const { instanceId, tagGroups } = useValuSearch();
    const single = useHasSingleGroup();

    if (single) {
        return true;
    }

    const groupId = query.get(instanceId + "_id") || undefined;

    // return true only when the group id in the url is a known group id
    return tagGroups.some((group) => {
        return group.id === groupId;
    });
}

export function useGroup(): ReactGroup | undefined {
    let groupId = useContext(GroupIdContext);
    const query = useQueryValue();
    const { instanceId, tagGroups } = useValuSearch();
    const single = useHasSingleGroup();

    if (single && tagGroups[0]) {
        return tagGroups[0];
    }

    if (groupId === undefined) {
        groupId = query.get(instanceId + "_id") || undefined;
    }

    return tagGroups.find((group) => {
        return group.id === groupId;
    });
}

const emptyResult = {
    hits: [],
    total: 0,
};

export interface OrderedGroups {
    id: string;
    title: string;
    groupResults: {
        hits: SearchResult[];
        total: number;
        duration?: number | undefined;
    };
    scoreBoost: number;
}

export function useOrderedGroups(): OrderedGroups[] {
    const results = useSearchResults();
    const { tagGroups, orderBy } = useValuSearch();

    const orderedGroups = React.useMemo(() => {
        const sortArray = tagGroups.map((group) => {
            const groupResults = results[group.id] || emptyResult;
            return {
                id: group.id,
                title: group.title,
                groupResults: groupResults,
                scoreBoost: group.scoreBoost ?? 0,
            };
        });
        switch (orderBy) {
            case "tagName": {
                return sortArray.sort((a, b) => {
                    return a.title.localeCompare(b.title);
                });
            }
            case "total": {
                return sortArray.sort((a, b) => {
                    return b.groupResults.total - a.groupResults.total;
                });
            }
            case "score": {
                return sortArray.sort((a, b) => {
                    const aVal = a.groupResults.hits[0]
                        ? a.groupResults.hits[0].score * a.scoreBoost
                        : 0;
                    const bVal = b.groupResults.hits[0]
                        ? b.groupResults.hits[0].score * b.scoreBoost
                        : 0;
                    return bVal - aVal;
                });
            }
            case "originalNotEmpties": {
                return sortArray.sort((a, b) => {
                    if (a.groupResults.total === 0) {
                        return 1;
                    }
                    if (b.groupResults.total === 0) {
                        return -1;
                    }
                    return 0;
                });
            }
            case "original": {
                return sortArray;
            }
        }
        // exhaustive switch case check
        const _: never = orderBy;
    }, [orderBy, results, tagGroups]);

    return orderedGroups;
}
