import React from "react";
import { useValuSearch } from "../config";

export function useBodyClasses(isOpen: boolean) {
    const { instanceId } = useValuSearch();
    React.useEffect(() => {
        if (isOpen) {
            window.document.body.classList.add(
                "valu-search-open-" + instanceId,
            );
            window.document.body.classList.remove(
                "valu-search-closed-" + instanceId,
            );
        } else {
            window.document.body.classList.remove(
                "valu-search-open-" + instanceId,
            );
            window.document.body.classList.add(
                "valu-search-closed-" + instanceId,
            );
        }
    }, [isOpen, instanceId]);
}
