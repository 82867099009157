import React, { ComponentProps, useState } from "react";
import { useSearchTerms } from "../utils/use-search-terms";
import { css } from "react-bemed/css";
import { useValuSearch } from "../config";
import { useQueryValue } from "../utils/query-context";
import {
    assertNonNullable,
    deprecateLog,
    u,
    useGetGroupLinkFromWindow,
} from "../utils/helpers";
import { historyPushState } from "../utils/shared-helpers";
import { bemed } from "./bemed";
import { useBrowserContext } from "../utils/browser-context";
import { CloseIcon, SearchIcon } from "./ReactIcons";
import { useGroup, useOrderedGroups } from "./GroupInfo";
import { useHit } from "./HitInfo";
import { Slot } from "./Slot";

const FullScreenSearchInputBlk = bemed({
    name: "FullScreenSearchInput",
    as: "input",

    css: css`
        box-sizing: border-box;
        font-size: ${u(16)};
        font-weight: 700;
        width: 100%;
        height: ${u(54)};
        line-height: ${u(36)};
        z-index: 9999;
        position: sticky;
        top: ${u(0)};
        width: 100%;
        max-width: ${u(750)};
        margin: 0 auto;
        padding: 0 ${u(16)} 0 ${u(58)};
        background: #f9f8f8;
        border: solid ${u(1)} #595959;
        border-radius: ${u(27)};
        transition: all 0.2s ease;
        :focus-visible {
            outline: none;
            border-radius: ${u(27)};
            border-color: #000;
            box-shadow: 0 0 0 ${u(1)} #000;
        }
    `,
});

const FullScreenSearchInputIcon = bemed({
    name: "FullScreenSearchInputIcon",

    css: css`
        position: absolute;
        left: ${u(21)};
        top: ${u(56)};
        z-index: 9999;
    `,

    mods: {
        isIE: css`
            display: none;
        `,
    },
});

const FullScreenLogoBlk = bemed({
    name: "FullScreenLogo",
    as: "img",

    css: css`
        margin-top: ${u(10)};
        width: auto;
        max-width: ${u(200)};
        height: ${u(50)};
        @media only screen and (min-width: ${u(750)}) {
            margin-top: ${u(24)};
        }
    `,
});

const FullScreenLogoContainerBlk = bemed({
    name: "FullScreenLogoContainer",

    css: css`
        width: 100%;
        max-width: ${u(750)};
        margin: 0 auto;
    `,
});

const FullScreenSearchInputContainerBlk = bemed({
    name: "FullScreenSearchInputContainer",

    css: css`
        position: sticky;
        top: ${u(0)};
        width: 100%;
        max-width: ${u(750)};
        margin: 0 auto;
        padding: ${u(42)} ${u(0)} ${u(16)} ${u(0)};
        background-color: #fff;
        z-index: 999;
    `,
});

const SearchResultExcerptBlk = bemed({
    name: "SearchResultExcerpt",
    as: "p",

    css: css`
        flex-direction: unset;
        margin-top: 0;
        margin-bottom: ${u(8)};
        line-height: 1.4;
        padding-left: ${u(12)};
        em {
            font-style: normal;
            color: #fff;
            padding: ${u(2)} ${u(5)};
            background-color: #f757b7;
            border-radius: ${u(2)};
        }
    `,
});

const SearchResultDateBlk = bemed({
    name: "SearchResultDate",
    as: "span",

    css: css`
        display: block;
        margin-bottom: ${u(8)};
    `,
});

const ValuSearchAdBlk = bemed({
    name: "ValuSearchAd",

    css: css`
        position: fixed;
        padding-top: ${u(8)};
        padding-right: ${u(12)};
        padding-bottom: ${u(8)};
        padding-left: ${u(8)};
        bottom: 0;
        right: 0;
        font-size: ${u(14)};
        background-color: #fff;
        @media only screen and (min-width: ${u(750)}) {
            padding-right: ${u(12)};
            padding-bottom: ${u(12)};
            right: ${u(16)};
        }
    `,

    elements: {
        Link: bemed({
            as: "a",
            css: css`
                text-decoration: none;
                color: black;
                :hover {
                    text-decoration: underline;
                }
            `,
        }),
    },

    mods: {
        isIE: css`
            display: none;
        `,
        isIEAndAnimationIsFinished: css`
            display: flex;
        `,
    },
});

/**
 * Retuns a title component with provided values with event emitter
 * Usable only from hit() Slot.
 * @param props
 */
export function SearchResultTitle(props: {
    title?: string;
    url?: string;
    score?: number;
}) {
    const hit = useHit();
    return (
        <HitTitle>
            <SearchResultUrl
                title={props.title ?? hit.title}
                url={props.url ?? hit.url}
                score={props.score ?? hit.score}
                renderedTitle={props.title ?? hit.title}
            />
        </HitTitle>
    );
}

/**
 * Generic title component with data-testid, score and class presets.
 * Usable only from hit() Slot.
 */
export const ResultTitle = React.forwardRef<
    HTMLHeadingElement,
    ComponentProps<"h3">
>((props, ref) => {
    const hit = useHit();
    const { instanceId } = useValuSearch();
    return (
        <h3
            {...props}
            ref={ref}
            data-score={hit.score}
            data-testid={"hit-title"}
            className={`${instanceId}-SearchResultTitle`}
        >
            {props.children}
        </h3>
    );
});

const HitTitle = bemed({
    name: "SearchResultTitle",
    as: ResultTitle,

    css: css`
        position: relative;
        padding-left: ${u(10)};
        margin-bottom: ${u(12)};
        margin-top: ${u(0)};
        line-height: 1.5;
        border-left: solid 2px #f757b7;
    `,
});

/**
 * Returns an excerpt text component in <p> tag
 * Usable only from hit() Slot.
 * @param props
 */
export function SearchResultExcerpt(props: { highlight?: string }) {
    const hit = useHit();
    const { instanceId } = useValuSearch();

    return (
        <SearchResultExcerptBlk
            data-testid={"hit-excerpt"}
            aria-describedby={`valu-search-excerpt-information-${instanceId}`}
            dangerouslySetInnerHTML={{
                __html: props.highlight ?? hit.highlight,
            }}
        ></SearchResultExcerptBlk>
    );
}

/**
 * Returns Date component in <span> converted to a local time string according to config.lang
 * Use this for created and modified dates
 * Usable only from hit() Slot.
 * @param props
 */
export function SearchResultDate(props: { date: Date }) {
    const { uiStrings, lang } = useValuSearch();

    return (
        <SearchResultDateBlk
            aria-label={
                uiStrings.searchResultDate +
                " " +
                new Date(props.date).toLocaleDateString(lang)
            }
            data-testid="hit-date"
        >
            {new Date(props.date).toLocaleDateString(lang)}
        </SearchResultDateBlk>
    );
}

export function FullScreenHeader(props: {
    logo?: React.ReactNode;
    input: React.ReactNode;
}) {
    return (
        <>
            {props.logo}
            {props.input}
        </>
    );
}

const FullScreenInputAndCloseContainerBlk = bemed({
    name: "FullScreenInputAndCloseContainer",

    css: css`
        max-width: ${u(750)};
        width: 100%;
        margin: 0 auto;
        position: sticky;
        top: 0;
        height: ${u(112)};
        z-index: 999;
    `,
});

const CloseIconContainerFS = bemed({
    name: "CloseIconContainer",

    css: css`
        display: flex;
        flex-direction: row;
        position: absolute;
        right: ${u(16)};
        z-index: 10000;
    `,

    elements: {
        Button: bemed({
            as: "button",
            css: css`
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                text-decoration: none;
                color: #000;
                padding: ${u(6)} 0;
                border: none;
                font: inherit;
                color: inherit;
                background: none;
            `,
        }),
        Text: bemed({
            as: "span",
            css: css`
                font-weight: 700;
                margin-right: ${u(3)};
            `,
        }),
        Icon: bemed({
            css: css`
                display: flex;
            `,
        }),
    },
});

/**
 * Comes with sticky close button above the input on the right.
 * Remember to remove other close button with slotOverride when using this component
 */
export function FullScreenInputWithCloseButton() {
    const { uiStrings, deactivate } = useValuSearch();
    return (
        <FullScreenInputAndCloseContainerBlk>
            <CloseIconContainerFS>
                <CloseIconContainerFS.Button
                    aria-label={uiStrings.closeSearch}
                    data-testid="close-button"
                    onClick={() => deactivate()}
                >
                    <CloseIconContainerFS.Text>
                        {uiStrings.close}
                    </CloseIconContainerFS.Text>
                    <CloseIconContainerFS.Icon>
                        <CloseIcon size={28} />
                    </CloseIconContainerFS.Icon>
                </CloseIconContainerFS.Button>
            </CloseIconContainerFS>
            <FullScreenSearchInputWrapper></FullScreenSearchInputWrapper>
        </FullScreenInputAndCloseContainerBlk>
    );
}

/**
 * Wraps FullScreenLogo in a container
 * @param props href=logo url, alt=alt
 */
export function FullScreenLogoWrapper(props: { href: string; alt?: string }) {
    return (
        <FullScreenLogoContainerBlk>
            <FullScreenLogoBlk src={props.href} alt={props.alt ?? "Logo"} />
        </FullScreenLogoContainerBlk>
    );
}

/**
 * Wraps FullScreenSearchInput in container
 */
export function FullScreenSearchInputWrapper() {
    const browser = useBrowserContext();
    const { useValuSearchInputConnect, uiStrings } = useValuSearch();
    const inputRef = useValuSearchInputConnect();

    return (
        <FullScreenSearchInputContainerBlk>
            <FullScreenSearchInputBlk
                ref={inputRef}
                type="text"
                id="valu-search-fs-input"
                data-testid="fs-terms-input"
                aria-label={uiStrings.searchInput}
            ></FullScreenSearchInputBlk>
            <FullScreenSearchInputIcon isIE={browser?.ie}>
                <SearchIcon size={24} />
            </FullScreenSearchInputIcon>
        </FullScreenSearchInputContainerBlk>
    );
}

/**
 * SearchResultUrl
 * Usable only from hit() Slot.
 */
export function SearchResultUrl(props: {
    title?: string;
    url?: string;
    score?: number;
    asTitle?: boolean;
    renderedTitle: string;
}) {
    const hit = useHit();

    const asTitle =
        props.asTitle ?? props.renderedTitle !== (props.url ?? hit.url);

    return (
        <SearchResultUrlBlk
            aria-hidden={asTitle ? undefined : "true"}
            data-testid={asTitle ? "hit-title-link" : "hit-url-link"}
            asTitle={asTitle}
            href={props.url ?? hit.url}
            tabIndex={asTitle ? undefined : -1}
        >
            {props.renderedTitle}
        </SearchResultUrlBlk>
    );
}

/**
 * Generic link component, with build in event integration
 * Usable only from hit() Slot.
 */
export const ResultLink = React.forwardRef<
    HTMLAnchorElement,
    ComponentProps<"a">
>((props, ref) => {
    const terms = useSearchTerms();
    const orderedGroups = useOrderedGroups();
    const { instanceId, lang, events } = useValuSearch();
    const qs = useQueryValue();
    const hit = useHit();
    const group = useGroup();
    assertNonNullable(group, "group missing in ResultLink");

    function handleClick() {
        assertNonNullable(group, "group missing in ResultLink/click");
        events.emitSearchResultClickedEvent({
            resultTitle: hit.title,
            groupTitle: group.title || "",
            url: hit.url,
            terms: terms,
            score: hit.score,
            lang: lang ?? "undefined",
            qs: qs,
            instanceId: instanceId,
            orderedGroups: orderedGroups,
        });
    }
    return (
        <a
            {...props}
            ref={ref}
            onClick={(e) => {
                handleClick();
                if (props.onClick) {
                    props.onClick(e);
                }
            }}
        >
            {props.children}
        </a>
    );
});

const SearchResultUrlBlk = bemed({
    name: "SearchResultUrl",
    as: ResultLink,

    css: css`
        display: block;
        padding-left: ${u(12)};
        text-decoration: none;
        overflow-wrap: break-word;
        word-wrap: break-word;
        color: #000;
        font-size: ${u(14)};
        line-height: 1.3;
    `,

    mods: {
        asTitle: css`
            display: initial;
            font-size: inherit;
            border-bottom: solid ${u(2)} transparent;
            transition: all 0.1s ease;
            padding-left: 0;
            :hover {
                border-bottom: solid ${u(2)} #000;
            }
        `,
    },
});

export function ValuSearchAd(props: {
    href?: string;
    children?: React.ReactNode;
}) {
    const { animationFinished } = useValuSearch();
    const browser = useBrowserContext();

    return (
        <ValuSearchAdBlk
            isIE={browser?.ie}
            isIEAndAnimationIsFinished={browser?.ie && animationFinished}
        >
            <ValuSearchAdBlk.Link
                href={props.href ?? "https://www.valusearch.pro/"}
            >
                {props.children ?? "Powered by Valu Search"}
            </ValuSearchAdBlk.Link>
        </ValuSearchAdBlk>
    );
}

const SearchResult = bemed({
    name: "SearchResult",

    css: css`
        margin-top: 2rem;
        margin-bottom: 1rem;
        flex: 1 0;
        flex-direction: column;
        justify-content: center;
        z-index: 100;
        width: 100%;
        max-width: ${u(750)};
        @media only screen and (min-width: ${u(750)}) {
            margin-top: 2.5rem;
        }
    `,
});

/**
 * Usable only from hit() Slot.
 */
export function Hit(props: {
    highlight?: string;
    date?: Date;
    title?: string;
    url?: string;
    score?: number;
}) {
    const hit = useHit();

    return (
        <SearchResult>
            <Slot
                name="hitInner"
                key={hit.url}
                params={{
                    id: hit.id,
                    title: hit.title,
                    highlight: hit.highlight,
                    url: hit.url,
                    created: hit.created,
                    score: hit.score,
                    modified: hit.modified,
                    customFields: hit.customFields,
                }}
            >
                <SearchResultDate date={props.date ?? hit.created} />
                <SearchResultTitle
                    title={props.title}
                    url={props.url}
                    score={props.score}
                />
                <SearchResultExcerpt highlight={props.highlight} />
                <SearchResultUrl
                    title={props.title}
                    url={props.url}
                    score={props.score}
                    renderedTitle={props.url ?? hit.url}
                />
            </Slot>
        </SearchResult>
    );
}

const NavWrapper = bemed({
    name: "NavWrapper",

    css: css`
        max-width: ${u(750)};
        margin: 0 auto;
        flex-direction: row;
        display: flex;
        justify-content: left;
    `,

    elements: {
        Item: bemed({
            css: css`
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-decoration: none;
                margin-right: ${u(24)};
                color: black;
                :hover {
                    text-decoration: underline;
                }
            `,
        }),
    },
});

export function GroupNav(props: {
    groups: { title: string; id: string; total: number }[];
}) {
    const searchTerms = useSearchTerms();
    const currentGroup = useGroup();
    const getGroupLinkFromWindow = useGetGroupLinkFromWindow();
    const [clicked, setClicked] = useState(false);
    const { events } = useValuSearch();

    React.useEffect(() => {
        if (!clicked) {
            return;
        }
        if (!currentGroup) {
            return;
        }
        const results =
            window.document.getElementsByClassName("vs-SearchResultUrl");

        if (results && results[0] instanceof HTMLElement) {
            results[0].focus({ preventScroll: true });
        }
        setClicked(false);
    }, [currentGroup, clicked]);

    return (
        <NavWrapper>
            {props.groups.map((group) => {
                return (
                    <NavWrapper.Item key={group.title}>
                        <ValuSearchLink
                            key={group.title}
                            href={getGroupLinkFromWindow({
                                id: group.id,
                                terms: searchTerms,
                            })}
                            onClick={() => {
                                events.emit({
                                    name: "group-nav-clicked",
                                    terms: searchTerms,
                                    groupTitle: group.title,
                                });
                                setClicked(true);
                            }}
                            dataTestId="group-nav-item"
                        >
                            {group.title + " " + group.total}
                        </ValuSearchLink>
                    </NavWrapper.Item>
                );
            })}
        </NavWrapper>
    );
}

/**
 * Common link component for inner links
 * @param props
 */
export function ValuSearchLink(props: {
    href: string;
    onClick?: () => void;
    dataTestId: string;
    children: React.ReactNode;
    className?: string;
}) {
    function handleClick(event: React.MouseEvent) {
        if (event.button === 1) {
            return; // do not capture middle click
        }

        event.preventDefault();

        historyPushState(props.href);

        if (props.onClick) {
            props.onClick();
        }
    }
    return (
        <a
            href={props.href}
            data-testid={props.dataTestId}
            onClick={(event) => handleClick(event)}
            className={props.className}
        >
            {props.children}
        </a>
    );
}

const FocusWrapper = bemed({
    name: "FocusWrapper",
});

export function FocusTrapContainer(props: { children: React.ReactNode }) {
    const { instanceId } = useValuSearch();
    deprecateLog(
        "The standalone <FocusTrapContainer> is deprececated. Use the one from the ValuSearch instance or its loader",
    );
    return (
        <FocusWrapper className={`valu-search-focus-trap-${instanceId}`}>
            {props.children}
        </FocusWrapper>
    );
}
