/* eslint-disable react-hooks/rules-of-hooks */
import { enableES5 } from "immer";
enableES5();
import React, { useState, useEffect, useMemo, ComponentProps } from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";

import { createSearchStore } from "./redux/store";
import { QueryProvider } from "./utils/query-context";
import { Main } from "./components/Main";
import {
    FullValuSearchConfig,
    ValuSearchConfigContext,
    UIStrings,
    ReactGroup,
    Slots,
    Group,
} from "./config";
import { bindActionCreators } from "redux";
import { SearchActions } from "./redux/actions";
import {
    getIsSearchActiveFromWindow,
    clearVSQueryParamsOnWindow,
    open,
    getCurrentGroupFromWindow,
} from "./utils/shared-helpers";
import {
    defaults,
    createAddressBarListener,
    deprecateLog,
    deprecate,
    deprecateSlot,
    featureFlag,
} from "./utils/helpers";
import { IsBrowserProvider } from "./utils/browser-context";
import { useIsSearchActive, useSearchTerms } from "./utils/use-search-terms";
import { useBodyClasses } from "./utils/use-body-classes";
import { SearchResultsViewer } from "./components/SearchResultsViewer";
import { AddressBarListener, SearchEngine } from "./redux/search-engine";
import { FocusTrapContainer } from "./components/SharedComponents";
import { ValuSearchEventListener, ValuSearchEvents } from "./events";
import { findkitFetch, CustomFields } from "@findkit/fetch";
import { useFocusTrap } from "./utils/use-focus-trap";

export * from "./components/SharedComponents";
export { useIsSearchActive } from "./utils/use-search-terms";
export * from "./utils/shared-helpers";
export { useValuSearch } from "./config";
export { ValuSearchEvent, ValuSearchBrowserEvent } from "./events";
export * from "./components/ReactIcons";
export { useGroupSearchResults, useIsSearching } from "./redux/hooks";

export { Group, Slots, CustomFields };

if (typeof window !== "undefined") {
    (window as any)["rvs-loaded"] = process.env.VERSION;
    console.log("[RVS] Loaded Valu Search v" + process.env.VERSION);

    // we would like to emit the VS Loaded event here, but we do not have access to instanceId yet
}

/**
 * English and fallback translations in UI
 */
export const defaultTranslation: UIStrings = {
    searchResultsTitle: "Search Results",
    moreResults: "Show more search results",
    moreResultsIn: "in",
    allShown: "All results shown",
    back: "Back",
    noResults: "No search results",
    close: "Close",
    closeSearch: "Close search",
    searchInput: "Search input",
    searchTerm: "Search term",
    searchResults: "Search results",
    searchResultGroup: "Result group",
    searchResultDate: "Page publication date",
    searchResultExcerpt: "Search term was found in the following context",
    searchInstructions:
        "Search shows search results automatically as you type. Search results can be browsed with tabulator. Search searches for results in different groups and displays group's search results from best to worst. Search opens to its own window which can be closed with ESC or with Close-button at the end of the search window.",
};

/**
 * Finnish and Swedish UI translations
 */
export const translations: Record<string, UIStrings | undefined> = {
    en: defaultTranslation,

    fi: {
        searchInstructions:
            "Hakutoiminto esittää hakutulokset automaattisesti kirjoittaessasi hakusanaa. Hakutuloksia on mahdollista selata tab-näppäimellä. Haku etsii hakutuloksia useista ryhmistä, ja esittää ryhmän hakutulokset paremmuusjärjestyksessä. Haku aukeaa omaan näkymään, jonka käyttäjä voi sulkea esc-näppäimellä tai haun lopussa olevasta sulje-painikkeesta.",
        searchResultsTitle: "Hakutulokset",
        moreResults: "Näytä lisää hakutuloksia",
        moreResultsIn: "ryhmässä",
        allShown: "Kaikki tulokset esitetty",
        back: "Takaisin",
        noResults: "Ei hakutuloksia",
        close: "Sulje",
        closeSearch: "Sulje haku",
        searchInput: "Hakukenttä",
        searchTerm: "Hakusana",
        searchResults: "Hakutuloksia",
        searchResultDate: "Sivun julkaisupäivämäärä",
        searchResultExcerpt: "Hakutulos löytyi seuraavasta kontekstista",
        searchResultGroup: "Tulosryhmä",
    },
    sv: {
        searchInstructions:
            "Search shows search results automatically as you type. Search results can be browsed with tabulator. Search searches for results in different groups and displays group's search results from best to worst. Search opens to its own window which can be closed with ESC or with Close-button at the end of the search window.", // XXX tranlsations
        searchResultsTitle: "Sökresultat",
        moreResults: "Visa fler sökresultat",
        moreResultsIn: "i",
        allShown: "Alla sökresultat visas",
        back: "Tillbaka",
        noResults: "Inga sökresultat",
        close: "Stäng",
        closeSearch: "Stäng sök",
        searchInput: "Search input", // XXX tranlsations
        searchTerm: "Sökterm",
        searchResults: "Sökresultat",
        searchResultDate: "Sidans publiceringsdatum",
        searchResultExcerpt: "Söktermen hittades i följande kontext",
        searchResultGroup: "Sökresultatgrupp",
    },
};

export interface ValuSearchUIConfig {
    instanceId: FullValuSearchConfig["instanceId"];
    uiStrings: UIStrings;
    /**
     * @deprecated Use .bindInput() or .useInput()
     */
    inputSelectors: string;
    validateTabbable: FullValuSearchConfig["validateTabbable"];
    orderBy: FullValuSearchConfig["orderBy"];
    /**
     * @deprecated Use "slots" instead
     */
    slotOverrides: FullValuSearchConfig["slots"];
    slots: FullValuSearchConfig["slots"];
}

/**
 * Options for the ValuSearch class
 */
export interface ValuSearchConfig {
    /**
     * Customer id string
     */
    customer?: string;

    /**
     * API key for the search endpint
     */
    apiKey: string;

    /**
     * Enable focus trapping. Defaults to true
     */
    focusTrap?: boolean;

    /**
     * Result group configuration. Customize group titles, filters, decays etc.
     */
    groups?: Group[];

    /**
     * Validate tabbable for the focus trapping
     */
    validateTabbable?: FullValuSearchConfig["validateTabbable"];

    /**
     * Customize the appearance via render props slots
     */
    slots?: Slots;

    /**
     * The group order. Default to "score"
     */
    orderBy?: FullValuSearchConfig["orderBy"];

    /**
     * @deprecated use uiLang and searchLang
     */
    lang?: string;

    /**
     * Set UI strings to lang
     */
    uiLang?: string;

    /**
     * Set searches to lang,
     * only first language from the array is supported
     * array type for possible future extensions to multiple languages
     * for now, leave undefined to search with all languages
     */
    searchLang?: string[] | undefined;

    /**
     * Custom instance id.  Only needed when running multiple RVS instances on
     * the same page. Affects the url query string names
     */
    instanceId?: string;

    /**
     * How many items to preview in the groups
     */
    previewGroupSize?: number;

    /**
     * How many results to search for in group view
     */
    searchMoreSize?: number;

    /**
     * Min search term lenght
     */
    minSearchTermsLength?: number;

    /**
     * How often a search is made when user types to an input
     */
    searchThrottleTime?: number;

    /**
     * @deprecated Just use the slotOverrides
     */
    mode?: "fullScreen";

    /**
     * @deprecated Use ValuSearch#bindInput()
     */
    inputSelectors?: string;

    /**
     * Event listener to be called on ValuSearchEvents
     */
    onEvent?: ValuSearchEventListener;

    /**
     * @deprecated Use "slots" instead
     */
    slotOverrides?: Slots;

    /**
     * @deprecated Use "customer" instead
     */
    searchEndpoint?: string;

    /**
     * @deprecated Use "groups" instead
     */
    tagGroups?: Group[];

    /**
     * Enable infinite scrolling by automatically loading results when the last
     * item appears to the screen
     */
    infiniteScroll?: boolean;
}

let stagingOrProduction = "v1-production";

// legacy
if (
    typeof window !== "undefined" &&
    window.location.href.indexOf("vs=staging") !== -1
) {
    deprecateLog('"vs=staging" has been renamed to "vsstaging"');
    stagingOrProduction = "v1-staging";
}

if (featureFlag("vsStaging")) {
    stagingOrProduction = "v1-staging";
}

let instances: ValuSearch[] = [];

/**
 * https://webpack.js.org/api/hot-module-replacement/#module-api
 */
interface WebpackModule {
    hot?: {
        status(): string;
        addStatusHandler?: (handler: (status: string) => void) => void;
    };
}

/**
 * Webpack Module global if using Wepback
 */
declare const module: WebpackModule | undefined;

if (typeof module !== "undefined") {
    // Clear instances on Webpack Hot Module replacement as it will mess up the
    // instanceId checks since it can cause multiple ValuSearch instances to
    // appear
    module.hot?.addStatusHandler?.((status) => {
        if (status === "prepare") {
            instances = [];
        }
    });
}

interface ProviderProps {
    children: React.ReactNode;
    /**@deprecated use uiLang and searchLang instead */
    lang?: string;
    uiLang?: string;
    searchLang?: string[] | undefined;
    uiStrings?: Partial<UIStrings>;
    groups?: Group[];
    disabled?: boolean;
    /**
     * @deprecated Use the "groups" prop instead
     */
    tagGroups?: Group[];
    slots?: Slots;
}

export class ValuSearch {
    readonly config: Readonly<FullValuSearchConfig>;
    initialized: boolean;
    instanceId: string;
    engine: SearchEngine;
    store: ReturnType<typeof createSearchStore>;
    constructorGroups?: Group[];

    events: ValuSearchEvents;

    hasBeenOpened: boolean;

    /**
     * @deprecated
     */
    legacyFullscreen = false;

    /**
     * @deprecated
     */
    legacyInputSelectors: string | undefined;

    constructor(config: ValuSearchConfig) {
        this.instanceId = config.instanceId || "vs";
        this.hasBeenOpened = false;

        if (typeof window !== "undefined") {
            const dup = instances.find(
                (vs) => vs.instanceId === this.instanceId,
            );

            if (dup) {
                throw new Error(
                    "[RVS] Duplicate instanceId found. When using multiple RVS instances you must pass a unique instanceId to each one",
                );
            }
        }

        if (!config.searchEndpoint && !config.customer) {
            throw new Error(
                "[RVS] 'customer' or 'searchEndpoint' must be passed to new ValuSearch()",
            );
        }

        if (!config.apiKey) {
            throw new Error(
                "[RVS] 'apiKey' must be passed to new ValuSearch()",
            );
        }

        const searchEndpoint =
            config.searchEndpoint ||
            `https://api.search.valu.pro/${stagingOrProduction}/customers/${config.customer}/multi-search2`;

        if (config.tagGroups) {
            deprecateLog("'tagGroups' has been renamed to 'groups'");
        }

        if (config.inputSelectors) {
            deprecateLog("'inputSelectors', use ValuSerch#bindInput() instead");
            this.legacyInputSelectors = config.inputSelectors;
        }

        if (config.slotOverrides) {
            deprecateLog(`"slotOverrides" is deprecated. Rename to "slots"`);
            config.slots = config.slotOverrides;
        }

        if ("mode" in config) {
            deprecateLog("'mode', just use the slot overrides");

            if (config.mode === "fullScreen") {
                this.legacyFullscreen = true;
            }
        }

        if (config.lang) {
            deprecateLog(`use uiLang and searchLang respectively`);
        }

        const uiLang = config.uiLang ?? config.lang ?? "en"; // backwards combatibility

        const defaultGroupTitle =
            translations[uiLang]?.searchResultsTitle ??
            defaultTranslation.searchResultsTitle;

        this.constructorGroups = config.groups ||
            config.tagGroups || [
                {
                    // Just add group with all documents if no groups defined
                    id: "default-group",
                    title: defaultGroupTitle,
                    filters: {
                        tagQuery: [],
                        highlightLength: 10,
                    },
                },
            ];

        const fullConfig: FullValuSearchConfig = defaults(config, {
            instanceId: this.instanceId,
            orderBy: "score",
            searchEndpoint,
            focusTrap: true,
            tagGroups: [],
            slots: {},
            infiniteScroll: true,
        });

        this.config = fullConfig;
        this.initialized = false;

        this.store = createSearchStore();

        this.events = new ValuSearchEvents(this);

        this.engine = this.buildEngine();

        this.bindEsc();
        if (config.onEvent) {
            this.addListener(config.onEvent);
        }
        instances.push(this);
    }

    /**
     * Just globally listen the Escape key and deactivate the instance when
     * pressed.
     */
    private bindEsc() {
        if (typeof document === "undefined") {
            return;
        }

        document.addEventListener("keydown", (e) => {
            // If search is active close it when esc is pressed
            // IE11 uses Esc
            if (e.key !== "Esc" && e.key !== "Escape") {
                return;
            }

            this.deactivate();
        });
    }

    getTerms = () => {
        return this.engine.getTerms();
    };

    private buildEngine = () => {
        const addressBar: AddressBarListener = createAddressBarListener(
            this.instanceId,
        );

        const engine = new SearchEngine({
            store: this.store,
            staticSearchParams: {
                apiKey: this.config.apiKey,
                searchEndpoint: this.config.searchEndpoint,
            },
            throttleTime: this.config.searchThrottleTime ?? 250,
            minSearchTermsLength: this.config.minSearchTermsLength,
            addressBarListener: addressBar,
            searchMoreSize: this.config.searchMoreSize,
            fetcher: findkitFetch,
            onSearch: (searchParams) => {
                return this.events.emitSearchEvent({
                    terms: searchParams.terms,
                });
            },

            onSearchResponse: () => {
                // we don't know the final groups yet
                // so we can't build handlers??
            },
        });

        if (this.constructorGroups) {
            engine.setGroups(
                this.toReactGroups(this.constructorGroups).map((group) => {
                    return {
                        id: group.id,
                        filters: group.filters,
                        scoreBoost: group.scoreBoost ?? 1,
                        size: this.config.previewGroupSize || 5,
                    };
                }),
            );
        }

        return engine;
    };

    /**
     * @deprecated
     */
    ValuSearchActions = deprecate("ValuSearchActions", "[none]", () =>
        bindActionCreators(SearchActions, this.store.dispatch),
    );

    /**
     * @deprecated
     */
    private connectOpenerOnWindow = (openerElement: Element) => {
        openerElement.addEventListener("click", () => {
            this.activate();
        });
    };

    /**
     * @deprecated use .initModal()
     */
    renderMultiSearchResultsOnWindow = deprecate(
        ".renderMultiSearchResultsOnWindow()",
        ".initModal()",
        (uiStrings?: UIStrings) => {
            this.initModal({ uiStrings });
        },
    );

    initModal = (options?: { uiStrings?: UIStrings }) => {
        if (this.initialized) {
            throw new Error("Cannot render Valu Search UI twice");
        }

        this.initialized = true;
        const searchContainer = window.document.createElement("div");
        searchContainer.className = "valu-search";
        searchContainer.style.zIndex = "999999";
        window.document.body.appendChild(searchContainer);

        ReactDOM.render(
            <this.Provider uiStrings={options?.uiStrings}>
                <this.Modal />
            </this.Provider>,
            searchContainer,
        );
    };

    /**
     * Connect Valu Search Results UI to an input element and one or more submit
     * buttons.
     *
     * @deprecated Use .bindInput() or .activate() instead
     *
     * @param options.inputs an input element or "FROM_LOADER" which will
     * autotomatically pick up the input from loader script
     */
    connectSearchElementsOnWindow = deprecate(
        "connectSearchElementsOnWindow",
        "bindInput/activate",
        (options: {
            inputs: HTMLCollectionOf<HTMLInputElement> | "FROM_LOADER";
            openers?: HTMLCollectionOf<Element> | "FROM_LOADER" | null;
        }) => {
            let inputs: HTMLCollectionOf<HTMLInputElement> | null = null;

            if (options.inputs === "FROM_LOADER") {
                inputs = (window as any).VALU_SEARCH_INPUTS;
                if (!inputs) {
                    throw new Error(
                        "No input(s) found from the VALU_SEARCH_INPUTS global. Did you use the loader script?",
                    );
                }
            } else if (options.inputs) {
                inputs = options.inputs;
            }

            if (!inputs) {
                throw new Error("Cannot find search input for Valu Search");
            }

            for (const input of Array.from(inputs)) {
                if (input instanceof HTMLInputElement) {
                    this.connectInputOnWindow(input);
                }
            }

            // Submit is optional up to this point
            if (!options.openers) {
                return;
            }

            let openers: HTMLCollectionOf<Element> | null = null;

            if (options.openers === "FROM_LOADER") {
                openers = (window as any).VALU_SEARCH_OPENERS;
                if (!openers) {
                    throw new Error(
                        "No opener(s) found from the VALU_SEARCH_OPENERS global. Did you use the loader script?",
                    );
                }
            }

            if (!openers) {
                throw new Error("Cannot find search opener for Valu Search");
            }

            for (const button of Array.from(openers)) {
                if (button instanceof Element) {
                    this.connectOpenerOnWindow(button);
                }
            }
        },
    );

    /**
     * Connects necessary listeners to Input and document
     * Returns an unmounting function
     * @param searchInputElement
     */
    connectInputOnWindow = deprecate(
        "connectInputOnWindow",
        "bindInput",
        (searchInputElement: HTMLInputElement | null) => {
            if (!searchInputElement) {
                throw new Error("Cannot find search input for Valu Search");
            }

            if (!searchInputElement.getAttribute("aria-describedby")) {
                searchInputElement.setAttribute(
                    "aria-describedby",
                    `valu-search-instructions-${this.instanceId}`,
                );
            }

            return this.bindInput(searchInputElement);
        },
    );

    /**
     * Set search terms to a given value
     */
    setSearchTerms = (terms: string) => {
        this.engine.api.setSearchParams({ terms });
    };

    bindInput = (input: HTMLInputElement) => {
        const added = this.engine.addInput(input);

        if (this.setInputs) {
            this.setInputs(this.engine.getInputs());
        }

        if (!input.getAttribute("aria-describedby")) {
            input.setAttribute(
                "aria-describedby",
                `valu-search-instructions-${this.instanceId}`,
            );
        }

        return added;
    };

    unbindInput(input: HTMLInputElement) {
        this.engine.removeInput(input);

        if (
            input.getAttribute("aria-describedby") ===
            `valu-search-instructions-${this.instanceId}`
        ) {
            input.removeAttribute("aria-describedby");
        }

        if (this.setInputs) {
            this.setInputs(this.engine.getInputs());
        }
    }

    /**
     * Bind input only as an opener. This for fullscreen modals where the
     * actual vs input is inside the modal but there's another input outside it
     * which should open the modal when typed on but not really work as bound
     * vs input.
     */
    bindInputAsOpener = (input: HTMLInputElement) => {
        const activate = () => {
            const terms = input.value;
            if (terms) {
                // Lazy loaded modules can experience input desync from outside input
                // It's necessary to listen to input changes until it loses focus
                const listenToChanges = () => {
                    this.setSearchTerms(input.value);
                };
                input.addEventListener("input", listenToChanges);

                const selfRemovingEventListener = () => {
                    // sync search terms one last time on focus out
                    this.setSearchTerms(input.value);
                    // also force sync inputs as inputs with focus are protected
                    this.engine.syncInputs(input.value, {
                        force: true,
                    });
                    input.removeEventListener("input", listenToChanges);
                    // We clear the opener input so it is empty when user
                    // deactivates vs and focus returns to eg. so user can start
                    // typing new search terms
                    input.value = "";
                    input.removeEventListener(
                        "focusout",
                        selfRemovingEventListener,
                    );
                };
                input.addEventListener("focusout", selfRemovingEventListener);

                // Move the search terms to the real VS input and activate it.
                this.setSearchTerms(terms);
                this.activate();
            }
        };

        input.addEventListener("input", activate, false);

        // Call immediately in the case vs was lazy loaded and user managed to
        // write something to the input before vs loaded
        activate();

        return () => {
            input.removeEventListener("input", activate, false);
        };
    };

    useInput = () => {
        const inputRef = React.useRef<HTMLInputElement | null>(null);
        const engine = this.engine;
        // XXX this is buggy. Need to use useCallback to get the dom element.
        // Not just useRef.
        React.useEffect(() => {
            if (inputRef.current) {
                const input = inputRef.current;
                this.bindInput(input);
                return () => {
                    this.unbindInput(input);
                };
            }
        }, [engine]);

        return inputRef;
    };

    /**
     * @deprecated Use .useInput()
     */
    useValuSearchInputConnect = deprecate(
        ".useValuSearchInputConnect()",
        ".useInput()",
        this.useInput,
    );

    /**
     * Manage RVS active status
     */
    useStatus = () => {
        const isActive = useIsSearchActive();

        return useMemo(() => {
            return {
                isActive,
                activate: this.activate,
                deactivate: this.deactivate,
            };
        }, [isActive]);
    };

    /**
     * Get and set search terms like useState()
     */
    useSearchTerms = () => {
        const terms = useSearchTerms();
        return [terms, this.setSearchTerms] as const;
    };

    private UseValuSearchHooks = () => {
        const isSearchActive = useIsSearchActive();

        useBodyClasses(isSearchActive);

        useFocusTrap(isSearchActive);

        useEffect(() => {
            if (isSearchActive) {
                this.events.emit({ name: "opened" });
                this.hasBeenOpened = true;
            } else {
                if (this.hasBeenOpened) {
                    this.events.emit({ name: "closed" });
                }
            }
        }, [isSearchActive]);

        return null;
    };

    private setInputs?: React.Dispatch<
        React.SetStateAction<HTMLInputElement[]>
    >;

    toReactGroups(groups: Group[]): ReactGroup[] {
        return groups.map((group, index) => {
            if (group.tagGroupId) {
                deprecateLog(
                    "group.tagGroupId is deprecated. Use the group.id instead",
                );
            }

            return {
                id: group.id || group.tagGroupId || String(index),
                scoreBoost: group.scoreBoost ?? 1,
                ...group,
            };
        });
    }

    Provider = (props: ProviderProps) => {
        // Ensure the provider only renders in the browser. Searches can be only
        // made in the browsers so it makes no sense to render anything during
        // SSR.
        const [isBrowser, setIsBrower] = useState(false);

        useEffect(() => {
            setIsBrower(true);
        }, []);

        if (!isBrowser) {
            return null;
        }

        return <this.ProviderInner {...props} />;
    };

    getSearchLang = (props: ProviderProps) => {
        if (props.searchLang) {
            return props.searchLang;
        } else if (this.config.searchLang) {
            return this.config.searchLang;
        } else if (props.lang) {
            return [props.lang];
        } else if (this.config.lang) {
            return [this.config.lang];
        } else {
            return undefined;
        }
    };

    private ProviderInner = (props: ProviderProps) => {
        const lang = props.lang || this.config.lang;
        const uiLang =
            props.uiLang ||
            props.lang ||
            this.config.uiLang ||
            this.config.lang;
        const searchLang = this.getSearchLang(props);

        let translation: UIStrings | undefined;
        const internalGroups: ReactGroup[] | undefined = useMemo(() => {
            if (props.tagGroups) {
                deprecateLog(
                    "<Provider tagGroups> is deprecated. Use the 'groups' prop",
                );
            }

            const groups =
                props.groups || props.tagGroups || this.constructorGroups;

            if (!groups) {
                return;
            }

            return this.toReactGroups(groups);
        }, [props.groups, props.tagGroups]);

        // should be first engine hook
        // it's important to disable engine first,
        // so the following egine hooks do not cause requests
        useEffect(() => {
            if (props.disabled === true) {
                this.engine.disable();
            }
        }, [props.disabled]);

        useEffect(() => {
            this.events.emit({ name: "loaded" });
        }, []);

        if (uiLang && translations[uiLang]) {
            translation = translations[uiLang];
        }

        // We need to sync the inputs to the react state so the internal
        // focus-trap hook can update itself when the added inputs change
        const [inputs, setInputs] = useState<HTMLInputElement[]>(
            this.engine.getInputs(),
        );

        // Allow react input state modification via the instance methods
        this.setInputs = setInputs;
        useEffect(
            () => () => {
                this.setInputs = undefined;
            },
            [],
        );

        // XXX This effect can removed when css input selectors are no longer used
        useEffect(() => {
            const selectors = [
                `#valu-search-input-${this.instanceId}`,
                `.valu-search-submit-${this.instanceId}`,
                `#valu-search-fs-input-${this.instanceId}`,
            ];

            if (this.legacyInputSelectors) {
                selectors.push(this.legacyInputSelectors);
            }

            const inputs = Array.from(
                document.querySelectorAll(selectors.join(",")),
            ).filter((node): node is HTMLInputElement => {
                return node instanceof HTMLInputElement;
            });

            for (const input of inputs) {
                const added = this.bindInput(input);
                if (added) {
                    deprecateLog(
                        "Binding input using css selectors. Please use explicit ValuSearch#bindInput()",
                        input,
                    );
                }
            }

            return () => {
                for (const input of inputs) {
                    this.unbindInput(input);
                }
            };
        }, []);

        useEffect(() => {
            if (!internalGroups) {
                return;
            }

            this.engine.setGroups(
                internalGroups.map((group) => {
                    return {
                        id: group.id,
                        filters: group.filters,
                        scoreBoost: group.scoreBoost ?? 1,
                        size: this.config.previewGroupSize || 5,
                    };
                }),
            );
        }, [internalGroups]);

        useEffect(() => {
            this.engine.api.setSearchParams({ searchLang });
        }, [searchLang]);

        this.engine.onSearchResponse = (searchOptions) => {
            const formResponses = () => {
                const responses: {
                    groupTitle: string;
                    total: number;
                    lang: string | undefined;
                }[] = [];
                searchOptions.responses.forEach((response, index) => {
                    if (
                        !internalGroups ||
                        typeof internalGroups[index]?.title === undefined
                    ) {
                        throw new Error(
                            "[RVS] Bug: could not find group title",
                        );
                    } else {
                        responses.push({
                            groupTitle:
                                internalGroups[index]?.title || "no-title",
                            total: response.total,
                            lang: searchOptions.engineFullSearchParams.groups[
                                index
                            ]?.lang,
                        });
                    }
                });
                return responses;
            };

            const groupDetailsEvent = () => {
                // needs to have group id
                if (getCurrentGroupFromWindow(this.instanceId) === "") {
                    return;
                }

                return true;
            };

            if (groupDetailsEvent()) {
                // separate event for group view event
                this.events.emitSearchResponseGroupDetailsEvent({
                    terms: searchOptions.engineFullSearchParams.terms,
                    lang: searchOptions.engineFullSearchParams.groups[0]?.lang, // lang is group specific, just pass the lang from first group here
                    responses: formResponses(),
                });
            } else {
                // This emits search response event and debounced search response event
                this.events.emitSearchResponseEvent({
                    terms: searchOptions.engineFullSearchParams.terms,
                    lang: searchOptions.engineFullSearchParams.groups[0]?.lang,
                    responses: formResponses(),
                });
            }
        };

        const [animationFinished, setAnimationFinished] = useState(false);
        const [grouppedPreviewLoaded, setGrouppedPreviewLoaded] =
            useState(false);

        // should be last engine hook
        // it's important to enable engine last
        // so previous engine hooks do not trigger requests prematurely
        // before all changes have been made
        useEffect(() => {
            // undefined and true are ok  values for engine to be enabled
            if (props.disabled !== true) {
                this.engine.enable();
            }
        }, [props.disabled]);

        /**
         * Start with default translation.
         * Override it with given alternative translation
         * And finally override with any given explicit overrides
         */
        const uiStrings: UIStrings = {
            ...defaultTranslation,
            ...translation,
            ...props.uiStrings,
        };

        const slots = {
            ...this.config.slots,
            ...props.slots,
        };

        deprecateSlot("fullScreenHeader", "modalHeader", slots);

        if (this.legacyFullscreen) {
            // In legacy fullscreen mode remove the default input with the close
            // button
            if (!slots.modalHeader) {
                slots.modalHeader = () => {
                    return null;
                };
            }
        } else {
            // In the modern version remove the close button since there's
            // already one in the input
            if (!slots.closeButton) {
                slots.closeButton = () => {
                    return null;
                };
            }
        }

        return (
            <ReduxProvider store={this.store as any}>
                <ValuSearchConfigContext.Provider
                    value={{
                        instanceId: this.config.instanceId,
                        uiStrings,
                        lang,
                        uiLang,
                        searchLang,
                        inputs,
                        focusTrap: this.config.focusTrap,
                        validateTabbable: this.config.validateTabbable,
                        tagGroups: internalGroups || [],
                        minSearchTermsLength: this.engine.minSearchTermsLength,
                        apiKey: this.engine.staticSearchParams.apiKey,
                        searchEndpoint:
                            this.engine.staticSearchParams.searchEndpoint,
                        previewGroupSize: this.config.previewGroupSize, // this is going to be removed, new engine architecture allows defining this on per group basis so we should do this there.
                        orderBy: this.config.orderBy,
                        slots,
                        _config: this.config,
                        useValuSearchInputConnect:
                            this.useValuSearchInputConnect,
                        deactivate: this.deactivate,
                        ...this.engine.api,
                        animationFinished,
                        setAnimationFinished,
                        grouppedPreviewLoaded,
                        setGrouppedPreviewLoaded,
                        events: this.events,
                        infiniteScroll: this.config.infiniteScroll,
                    }}
                >
                    <IsBrowserProvider>
                        <QueryProvider>
                            <this.UseValuSearchHooks />
                            {props.children}
                        </QueryProvider>
                    </IsBrowserProvider>
                </ValuSearchConfigContext.Provider>
            </ReduxProvider>
        );
    };

    ValuSearchProvider = deprecate(
        "<ValuSearchProvider>",
        "<Provider>",
        this.Provider,
    );

    Modal = (props: { topMargin?: number | string }) => {
        return <Main topMargin={props.topMargin} />;
    };

    /**
     * @deprecated Use <Results />
     */
    SearchResultsViewerComponent = deprecate(
        "<SearchResultsViewerComponent>",
        "<Results>",
        SearchResultsViewer,
    );

    Results = (props: {
        header?: React.ReactNode;
        footer?: React.ReactNode;
    }) => {
        return (
            <FocusTrapContainer>
                {props.header}
                <SearchResultsViewer />
                {props.footer}
            </FocusTrapContainer>
        );
    };

    /**
     * If you have custom components that you want to include in
     * focus trap, wrap VS UI with this component
     *
     * e.g.
     * <vs.FocusTrapContainer>
     *    <CustomFocusableElement />
     *    <vs.SearchResultsViewerComponent />
     * </vs.FocusTrapContainer>
     * @param props
     */
    FocusTrapContainer = React.forwardRef<
        HTMLDivElement,
        ComponentProps<"div">
    >((props, ref) => {
        let className = this.trapClassName;

        if (props.className) {
            className += " " + props.className;
        }

        return (
            <div {...props} ref={ref} className={className}>
                {props.children}
            </div>
        );
    });

    /**
     * Helper function for closing Valu Search.
     * Handles history api calls for you.
     */
    deactivate = () => {
        clearVSQueryParamsOnWindow(this.instanceId);
    };

    /**
     * @deprecated use .deactivate()
     */
    close = deprecate("close", "deactivate", this.deactivate);

    activate = () => {
        open({
            instanceId: this.instanceId,
        });
    };

    isActive = () => {
        return getIsSearchActiveFromWindow(this.instanceId);
    };

    addListener = (listener: ValuSearchEventListener) => {
        this.events.addListener(listener);
        return () => {
            this.events.removeListener(listener);
        };
    };

    /**
     * Class name used to create FocusTrap containers
     */
    get trapClassName() {
        return `valu-search-focus-trap-${this.instanceId || "vs"}`;
    }
}

const defaultVSConfig: FullValuSearchConfig = {
    instanceId: "vs",
    orderBy: "score",
    searchEndpoint: "this-will-be-overwritten",
    apiKey: "this-will-be-overwritten-too",
    tagGroups: [],
    lang: "fi",
    slots: {},
    focusTrap: true,
    infiniteScroll: true,
};

/**
 * @deprecated For backwards compatibility reasons only, do not use in post 14.0.0 installations
 * Only use this if connectSearchElements() is called with "FROM_LOADER", values
 */
export const renderMultiSearchResults = deprecate(
    "renderMultiSearchResults()",
    "ValuSearch#initModal()",
    (config: ValuSearchConfig & { uiStrings?: UIStrings }) => {
        const vs = new ValuSearch({ ...defaultVSConfig, ...config });
        vs.connectSearchElementsOnWindow({
            inputs: "FROM_LOADER",
            openers: "FROM_LOADER",
        });
        vs.renderMultiSearchResultsOnWindow(config.uiStrings);
        return vs;
    },
);

export const vsOpenOnWindow = open;

export const VERSION: string = process.env.VERSION ?? "dev";
