import { ValuSearch } from ".";
import { OrderedGroups } from "./components/GroupInfo";
import { SearchResult } from "./redux/state";
import { debounce } from "./utils/helpers";

export type ValuSearchEvent =
    | {
          /**
           * Fired after RVS has mounted
           * and all its components are in DOM
           */
          name: "loaded";
          instance: ValuSearch;
      }
    | {
          /**
           * Fired on RVS UI open
           */
          name: "opened";
          instance: ValuSearch;
      }
    | {
          /**
           * Fired on RVS UI closed
           */
          name: "closed";
          instance: ValuSearch;
      }
    | {
          /**
           * Fired when user clicks a search result
           */
          name: "search-result-clicked";
          terms: string;
          url: string;
          resultTitle: string;
          instance: ValuSearch;
      }
    | {
          /**
           * Fired on each search except for search more
           * searches.
           * NOTE: RVS searches as you type,
           * this event is fired a lot.
           */
          name: "search";
          terms: string;
          instance: ValuSearch;
      }
    | {
          /**
           * Fired with debounce from searches
           * except for search more searches.
           */
          name: "search-debounced";
          terms: string;
          instance: ValuSearch;
      }
    | {
          /**
           * Fired when navigating to group details
           * from groupped preview.
           */
          name: "group-results-clicked";
          terms: string;
          groupTitle: string;
          instance: ValuSearch;
      }
    | {
          /**
           * Fired when extra component GroupNav is
           * clicked.
           */
          name: "group-nav-clicked";
          terms: string;
          groupTitle: string;
          instance: ValuSearch;
      }
    | {
          /**
           * Fired when the search UI back button is
           * clicked.
           */
          name: "back-button-clicked";
          terms: string;
          groupTitle: string;
          instance: ValuSearch;
      }
    | {
          /**
           * Fired when RVS gets search response in
           * groupped preview
           */
          name: "search-response";
          terms: string;
          countTotal: number;
          responseGroups: {
              groupTitle: string;
              total: number;
              lang: string | undefined;
          }[];
          lang: string;
          instance: ValuSearch;
      }
    | {
          /**
           * Fired with debounce after getting search
           * response / responses in groupped preview
           */
          name: "search-response-debounced";
          terms: string;
          countTotal: number;
          responseGroups: {
              groupTitle: string;
              total: number;
              lang: string | undefined;
          }[];
          lang: string;
          instance: ValuSearch;
      }
    | {
          /**
           * Fired when RVS gets search response in
           * group details.
           *
           * NOTE: When scrolling for more search results
           * this event is fired with each search response.
           */
          name: "search-response-group-details";
          terms: string;
          countTotal: number;
          responseGroups: {
              groupTitle: string;
              total: number;
              lang: string | undefined;
          }[];
          lang: string;
          instance: ValuSearch;
      }
    | {
          /**
           * Fired with debounce after getting search
           * response in group details view
           */
          name: "search-response-group-details-debounced";
          terms: string;
          countTotal: number;
          responseGroups: {
              groupTitle: string;
              total: number;
              lang: string | undefined;
          }[];
          lang: string;
          instance: ValuSearch;
      }
    | {
          /**
           * Fired when group result is clicked.
           */
          name: "search-result-clicked-extended";
          terms: string;
          url: string;
          resultTitle: string;
          normalizedRelevancy: number;
          position: number;
          lang: string;
          uiLocation: string;
          groupTitle: string;
          bestResult: { title: string; url: string };
          instance: ValuSearch;
      };

export interface ValuSearchBrowserEvent extends Event {
    valuSearchEvent: ValuSearchEvent;
}

export interface ValuSearchEventListener {
    (event: ValuSearchEvent): void;
}

/**
 * Omit from union
 * https://stackoverflow.com/a/57103940/153718
 */
type DistributiveOmit<T, K extends keyof any> = T extends any
    ? Omit<T, K>
    : never;

export class ValuSearchEvents {
    instance: ValuSearch;
    listeners: ValuSearchEventListener[];

    constructor(instance: ValuSearch) {
        this.instance = instance;
        this.listeners = [];
    }

    emit(event: DistributiveOmit<ValuSearchEvent, "instance">) {
        this.listeners.forEach((listener) =>
            listener({ ...event, instance: this.instance }),
        );

        let browserEvent;

        try {
            browserEvent = new Event("valu-search-event", {
                bubbles: true,
                cancelable: true,
            });
        } catch (error) {
            // catch IE error
            browserEvent = window.document.createEvent("Event");
            browserEvent.initEvent("valu-search-event", true, true);
        }
        (browserEvent as any).valuSearchEvent = event;
        window.document.dispatchEvent(browserEvent);
    }

    emitDebouncedResponse = debounce(this.emit, 500);

    emitSearchResponseEvent(options: {
        terms: string;
        responses: {
            groupTitle: string;
            total: number;
            lang: string | undefined;
        }[];
        lang: string | undefined;
    }) {
        const countTotal = options.responses.reduce(
            (sum, current) => sum + current.total,
            0,
        );

        const event: ValuSearchEvent = {
            name: "search-response",
            terms: options.terms,
            countTotal: countTotal,
            responseGroups: options.responses,
            lang: options.lang ?? "unknown",
            instance: this.instance,
        };

        this.emit(event);

        this.emitDebouncedResponse({
            ...event,
            name: "search-response-debounced",
        });
    }

    /**
     * Adds a listener if it has not been added already.
     * Return true if listener was added and false if not.
     * @param listener
     */
    addListener(listener: ValuSearchEventListener) {
        // only add listener if it does not exist yet
        if (this.listeners.includes(listener)) {
            return false;
        }

        this.listeners.push(listener);
        return true;
    }

    removeListener(listener: ValuSearchEventListener) {
        const index = this.listeners.indexOf(listener);
        if (index === -1) {
            return;
        }
        this.listeners.splice(index, 1);
    }

    // XXX this is outdated, should we just remove this
    emitSearchResultClickedEvent(options: {
        resultTitle: string;
        url: string;
        terms: string;
        score: number;
        lang: string | undefined;
        qs: URLSearchParams;
        groupTitle: string;
        instanceId: string;
        orderedGroups: OrderedGroups[];
    }) {
        const resultsShown: SearchResult[] = [];
        options.orderedGroups.forEach((group) => {
            group.groupResults.hits.forEach((result) => {
                resultsShown.push(result);
            });
        });

        const bestScore = Math.max(
            ...resultsShown.map((result) => result.score),
        );
        const bestResult = resultsShown.find(
            (result) => result.score === bestScore,
        );
        const clickedResult = resultsShown.find(
            (result) => result.score === options.score,
        );

        if (!bestResult || !clickedResult) {
            return;
        }

        const index = resultsShown.indexOf(clickedResult);
        const normalizedRelevancy = options.score / bestScore;

        const isMain = options.qs.has(options.instanceId + "_id");
        let uiLocation;
        if (isMain) {
            uiLocation = "MainView";
        } else {
            uiLocation = "GroupDetailView";
        }

        this.emit({
            name: "search-result-clicked-extended",
            terms: options.terms,
            resultTitle: options.resultTitle,
            url: options.url,
            normalizedRelevancy: normalizedRelevancy,
            position: index + 1,
            lang: options.lang ?? "unknown",
            uiLocation: uiLocation,
            groupTitle: options.groupTitle,
            bestResult: {
                title: bestResult.title,
                url: bestResult.url,
            },
        });
    }

    emitDebouncedSearch = debounce(this.emit, 500);

    emitSearchEvent(options: { terms: string }) {
        const event: ValuSearchEvent = {
            name: "search",
            terms: options.terms,
            instance: this.instance,
        };
        this.emit(event);

        this.emitDebouncedSearch({ ...event, name: "search-debounced" });
    }

    emitDebouncedSearchGroupDetails = debounce(this.emit, 500);

    emitSearchResponseGroupDetailsEvent(options: {
        terms: string;
        responses: {
            groupTitle: string;
            total: number;
            lang: string | undefined;
        }[];
        lang: string | undefined;
    }) {
        const countTotal = options.responses.reduce(
            (sum, current) => sum + current.total,
            0,
        );

        const event: ValuSearchEvent = {
            name: "search-response-group-details",
            terms: options.terms,
            countTotal: countTotal,
            responseGroups: options.responses,
            lang: options.lang ?? "unknown",
            instance: this.instance,
        };

        this.emit(event);

        this.emitDebouncedSearchGroupDetails({
            ...event,
            name: "search-response-group-details-debounced",
        });
    }
}
