import { FullValuSearchConfig, useValuSearch } from "../config";
import {
    setSearchTerms,
    parseSearchTerms,
    isSearchActive,
    getCurrentSearchTermsFromWindow,
    getIsSearchActiveFromWindow,
    setGroupIdOnWindow,
    getGroupLinkFromWindow,
    getGrouppedResultsLinkFromWindow,
    removeVSQueryParams,
    clearVSQueryParamsOnWindow,
    getInnerLinkFromWindow,
    open,
    getCurrentGroupFromWindow,
    dispatchValuSearchHistoryEvent,
} from "./shared-helpers";
import React, { useRef } from "react";
import { AddressBarListener } from "../redux/search-engine";

/**
 * CSS helper for defining element sizes.
 * If there is no env variable VS_REM_DIVIDER, returns `${value}px`
 * If VS_REM_DIVIDER is set returns ${value / process.env.HTML_ROOT_FONT_SIZE}rem
 *
 * In CDN projects set VS_REM_DIVIDER in customer/webpack.config.js
 * @param value
 */
export function u(value: number | string) {
    if (typeof value === "string") {
        return value;
    }

    if (process.env.VS_REM_DIVIDER) {
        return `${value / Number(process.env.VS_REM_DIVIDER)}rem`;
    }
    return `${value}px`;
}

const featureFlags = {
    vsExperimentalIdleThrottle: "🛑 Using experimental IdleDispatchStore!",
    vsDebug: "Debug mode enabled",
    vsStaging: "Using staging search endpoint!",
    vsLogResponseTimes: "Logging search response times",
};

const flagLogged: {
    [Flag in keyof typeof featureFlags]?: true;
} = {};

export function featureFlag(flag: keyof typeof featureFlags) {
    if (typeof window === "undefined") {
        return false;
    }

    const logged = flagLogged[flag];

    if (logged) {
        return true;
    }

    const active: boolean =
        window.location.search.toLowerCase().includes(flag.toLowerCase()) ||
        Boolean(window.localStorage.getItem(flag));

    if (active) {
        flagLogged[flag] = true;
        console.warn("[RVS flag] " + featureFlags[flag]);
    }

    return active;
}

export let debug = (msg: string, ...args: any[]) => {
    console.log("[RVS Debug] " + msg, ...args);
};

if (!featureFlag("vsDebug")) {
    debug = () => {};
}

export function requestIdleCallback(cb: () => void) {
    if (typeof window.requestIdleCallback === "function") {
        window.requestIdleCallback(cb);
    } else {
        setTimeout(cb, 0);
    }
}

/**
 * Fill missing values from the partial config with the default values
 */
export function defaults<PartialConfig extends {}, Defaults extends {}>(
    partialConfig: PartialConfig,
    defaultValues: Defaults,
) {
    const out: any = { ...partialConfig };
    for (const key in defaultValues) {
        if (out[key] === null || out[key] === undefined) {
            out[key] = defaultValues[key];
        }
    }
    // Output type is intersection of the partial config and the defaults
    return out as PartialConfig & Defaults;
}

/**
 * Typed and simplified version of https://www.npmjs.com/package/debounce
 */
export function debounce<T extends (...args: any[]) => any>(
    func: T,
    wait: number,
    immediate?: boolean,
) {
    let timeout: any, args: any, context: any, timestamp: any, result: any;
    if (null == wait) wait = 100;
    function later() {
        const last = Date.now() - timestamp;
        if (last < wait && last >= 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            if (!immediate) {
                result = func.apply(context, args);
                context = args = null;
            }
        }
    }
    function debounced(this: any, ..._args: Parameters<T>) {
        context = this;
        args = _args;
        timestamp = Date.now();
        const callNow = immediate && !timeout;
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }
        return result as undefined | ReturnType<T>;
    }
    debounced.cancel = () => {
        clearTimeout(timeout);
        timeout = null;
    };
    return debounced;
}

/**
 * From SO, must be perfect?
 * https://stackoverflow.com/a/25456134/153718
 */
export function isDeepEqual(x: any, y: any) {
    if (x === y) {
        return true;
    } else if (
        typeof x == "object" &&
        x != null &&
        typeof y == "object" &&
        y != null
    ) {
        if (Object.keys(x).length !== Object.keys(y).length) return false;

        for (const prop in x) {
            if (y.hasOwnProperty(prop)) {
                if (!isDeepEqual(x[prop], y[prop])) return false;
            } else return false;
        }

        return true;
    } else return false;
}

/**
 * This might be unnecessary
 * @param options
 */
export function partialConfigIsEqual(options: {
    partial: Partial<FullValuSearchConfig>;
    config: FullValuSearchConfig;
}): boolean {
    let matches = true;
    for (const [key, value] of Object.entries(options.partial)) {
        if (
            !Object.entries(options.config)[key as any] ||
            Object.entries(options.config)[key as any] !== value
        ) {
            matches = false;
        }
    }
    return matches;
}

export function useInstanceId() {
    const { instanceId } = useValuSearch();
    return instanceId;
}

export function useSetSearchTerms() {
    const instanceId = useInstanceId();
    return React.useCallback(
        (terms: string) => setSearchTerms(terms, instanceId),
        [instanceId],
    );
}

export function useParseSearchTerms() {
    const instanceId = useInstanceId();
    return React.useCallback(
        (searchParams: URLSearchParams) =>
            parseSearchTerms(searchParams, instanceId),
        [instanceId],
    );
}

export function useIsSearchActive() {
    const instanceId = useInstanceId();
    return React.useCallback(
        (searchParams: URLSearchParams) =>
            isSearchActive(searchParams, instanceId),
        [instanceId],
    );
}

export function useGetCurrentSearchTermsFromWindow() {
    const instanceId = useInstanceId();
    return React.useCallback(
        () => getCurrentSearchTermsFromWindow(instanceId),
        [instanceId],
    );
}

export function useGetIsSearchActiveFromWindow() {
    const instanceId = useInstanceId();
    return React.useCallback(
        () => getIsSearchActiveFromWindow(instanceId),
        [instanceId],
    );
}

export function useSetGroupIdOnWindow() {
    const instanceId = useInstanceId();
    return React.useCallback(
        (id: string) => setGroupIdOnWindow(id, instanceId),
        [instanceId],
    );
}

export function useGetGroupLinkFromWindow() {
    const instanceId = useInstanceId();
    return React.useCallback(
        (params: { id: string; terms: string }) =>
            getGroupLinkFromWindow({
                id: params.id,
                terms: params.terms,
                instanceId: instanceId,
            }),
        [instanceId],
    );
}

export function useGetGrouppedResultsLinkFromWindow() {
    const instanceId = useInstanceId();
    return React.useCallback(
        () => getGrouppedResultsLinkFromWindow(instanceId),
        [instanceId],
    );
}

export function useVsOpenOnWindow() {
    const instanceId = useInstanceId();
    return React.useCallback(
        (searchTerms: string) =>
            open({
                searchTerms: searchTerms,
                instanceId: instanceId,
            }),
        [instanceId],
    );
}

export function useVsCloseOnWindow() {
    const instance = useValuSearch();
    return React.useCallback(() => instance.deactivate(), [instance]);
}

export function useRemoveVSQueryParams() {
    const instanceId = useInstanceId();
    return React.useCallback(
        (params: URLSearchParams) => removeVSQueryParams(params, instanceId),
        [instanceId],
    );
}

export function useClearVSQueryParamsOnWindow() {
    const instanceId = useInstanceId();
    return React.useCallback(
        () => clearVSQueryParamsOnWindow(instanceId),
        [instanceId],
    );
}

export function useGetInnerLinkFromWindow() {
    const instanceId = useInstanceId();
    return React.useCallback(
        (params?: { searchTerms?: string; groupId?: string }) =>
            getInnerLinkFromWindow({
                searchTerms: params?.searchTerms,
                groupId: params?.groupId,
                instanceId: instanceId,
            }),
        [instanceId],
    );
}

export function usePreviousValue<T>(value: T) {
    const ref = useRef<T | null>(null);
    if (ref.current !== value) {
        ref.current = value;
    }
    return ref.current;
}

export function createAddressBarListener(
    instanceId: string,
): AddressBarListener {
    let triggerSearch: boolean = true;

    if (typeof window === "undefined") {
        return {
            getGroupId: () => undefined,
            setGroupId: () => {},
            getTerms: () => "",
            setTerms: () => {},
            listen: () => () => {},
        };
    }

    return {
        getGroupId: () => getCurrentGroupFromWindow(instanceId), // XXX should return undefined if there is no group id
        setGroupId: (id) => setGroupIdOnWindow(id, instanceId),
        getTerms: () => {
            return getCurrentSearchTermsFromWindow(instanceId);
        },
        setTerms: (terms) => {
            triggerSearch = false;
            setSearchTerms(terms, instanceId);
        },
        listen: (cb: Parameters<AddressBarListener["listen"]>[0]) => {
            if (typeof window === "undefined") {
                return () => {};
            }

            const onValuSearchHistoryEvent = () => {
                cb({ triggerSearch });
                triggerSearch = true;
            };

            const onPopState = () => {
                dispatchValuSearchHistoryEvent();
            };

            // Convert browser back/forward button presses to Valu Search History events
            window.addEventListener("popstate", onPopState);
            window.addEventListener(
                "valuSearchHistoryEvent",
                onValuSearchHistoryEvent,
            );

            return () => {
                window.removeEventListener("popstate", onPopState);
                window.removeEventListener(
                    "valuSearchHistoryEvent",
                    onValuSearchHistoryEvent,
                );
            };
        },
    };
}

const deprecateLogged: Record<string, boolean | undefined> = {};

/**
 * Logs the given deprecation message only once
 */
export function deprecateLog(msg: string, ...rest: any[]) {
    if (deprecateLogged[msg]) {
        return;
    }

    if (process.env.NODE_ENV !== "production") {
        console.warn(`[Deprecated] ${msg}`, ...rest);
        deprecateLogged[msg] = true;
    }
}

export function deprecate<Fn extends (...args: any[]) => any>(
    old: string,
    next: string,
    fn: Fn,
): Fn {
    const wrapped = (...args: any[]) => {
        if (process.env.NODE_ENV !== "production") {
            deprecateLog(`"${old}" has been deprecated. Please use "${next}"`);
        }

        return fn(...args);
    };

    return wrapped as Fn;
}

export function deprecateSlot<T extends keyof FullValuSearchConfig["slots"]>(
    old: T,
    next: T,
    slots: FullValuSearchConfig["slots"],
) {
    if (slots[old]) {
        deprecateLog(`Slot "${old}" has been deprecated. Please use "${next}"`);
        if (!slots[next]) {
            slots[next] = slots[old];
        }
    }
}

/**
 * @param ob Remove undefied keys from object. Just makes things cleaner for
 * tests
 */
export function cleanUndefined<T extends {}>(ob: T): T {
    const out = {} as T;

    for (const key in ob) {
        if (ob[key] !== undefined) {
            out[key] = ob[key];
        }
    }

    return out;
}

/**
 * Asserts that given object is not null or undefined
 */
export function assertNonNullable<T>(
    ob: T,
    assertionMessage: string,
): asserts ob is NonNullable<T> {
    if (ob === null || ob === undefined) {
        throw new Error(assertionMessage);
    }
}
