import React from "react";

type BrowserContextType = { ie: boolean } | null;

const BrowserContext = React.createContext<BrowserContextType | undefined>(
    undefined,
);

function isIEFromUserAgent() {
    return window?.navigator.userAgent.indexOf("Trident") !== -1;
}

/**
 * Returns undefined in non browser context
 * Returns {ie: boolean}, when browser, with information whether browser is ie or not
 */
function useBrowser() {
    const [browser, setBrowser] = React.useState<BrowserContextType>(null);
    React.useEffect(() => {
        setBrowser({ ie: isIEFromUserAgent() });
    }, []);
    return browser;
}

export function IsBrowserProvider(props: { children: React.ReactNode }) {
    const isBrowser = useBrowser();

    return (
        <BrowserContext.Provider value={isBrowser}>
            {props.children}
        </BrowserContext.Provider>
    );
}

export function useBrowserContext(): BrowserContextType {
    const context = React.useContext(BrowserContext);

    if (context === undefined) {
        throw new Error("BrowserContext is not provided");
    }

    return context;
}
