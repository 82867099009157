/**
 * Forked from https://github.com/chantastic/react-svg-spinner/blob/3911c525f37e41307f25253da864fe93ca74eebf/index.js
 * Due to https://github.com/chantastic/react-svg-spinner/issues/19
 */
import React from "react";

interface SpinnerProps {
    color?: string;
    thickness?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    gap?: 1 | 2 | 3 | 4 | 5;
    speed?: "fast" | "slow";
    size?: string;
}

function speedSwitch(speed: SpinnerProps["speed"]) {
    if (speed === "fast") return 600;
    if (speed === "slow") return 900;
    return 750;
}

export const Spinner = (props: SpinnerProps) => {
    const size = props.size ?? "1em";
    const thickness = props.thickness ?? 4;
    const color = props.color ?? "rgba(0,0,0,0.4)";
    const gap = props.gap ?? 4;

    return (
        <svg
            height={size}
            width={size}
            {...props}
            style={{ animationDuration: `${speedSwitch(props.speed)}ms` }}
            className="__react-svg-spinner_circle"
            role="img"
            aria-labelledby="title desc"
            viewBox="0 0 32 32"
        >
            <title id="title">Circle loading spinner</title>
            <desc id="desc">
                Image of a partial circle indicating "loading."
            </desc>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
      .__react-svg-spinner_circle{
          transition-property: transform;
          animation-name: __react-svg-spinner_infinite-spin;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
      }
      @keyframes __react-svg-spinner_infinite-spin {
          from {transform: rotate(0deg)}
          to {transform: rotate(360deg)}
      }
    `,
                }}
            />
            <circle
                role="presentation"
                cx={16}
                cy={16}
                r={14 - thickness / 2}
                stroke={color}
                fill="none"
                strokeWidth={thickness}
                strokeDasharray={Math.PI * 2 * (11 - gap)}
                strokeLinecap="round"
            />
        </svg>
    );
};
