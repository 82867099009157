import React, { useEffect } from "react";
import { css } from "react-bemed/css";
import { useIsSearchActive } from "../utils/use-search-terms";
import { Slot } from "./Slot";
import { useIsSearching } from "../redux/hooks";
import { u } from "../utils/helpers";
import { useValuSearch } from "../config";
import { bemed } from "./bemed";
import {
    ValuSearchAd,
    FullScreenInputWithCloseButton,
} from "./SharedComponents";
import { SearchResultsViewer } from "./SearchResultsViewer";
import { CloseIcon } from "./ReactIcons";
import { Spinner } from "./Spinner";

export const Blk = bemed({
    name: "Main",

    css: css`
        top: ${u(0)};
        right: ${u(0)};
        left: ${u(0)};
        bottom: ${u(0)};
        overflow: hidden;
        transition-delay: 0.3s;
        transform: translateY(-100vh);
        min-height: 0;
        position: fixed;
        visibility: hidden;
        @media print {
            display: none;
        }
    `,

    mods: {
        open: css`
            visibility: visible;
            transition-delay: 0s;
            transform: translateY(0%);
        `,
    },

    elements: {
        Container: bemed({
            css: css`
                height: 100%;
                position: absolute;
                top: ${u(0)};
                right: ${u(0)};
                left: ${u(0)};
                bottom: ${u(0)};
                transition: opacity 0.2s ease-in-out;
                transform: translateY(-100vh);
                opacity: 0;
                min-height: 0;
                overflow-y: auto;
                background-color: white;
            `,
            mods: {
                open: css`
                    transform: translateY(0%);
                    opacity: 1;
                `,
                animationFinished: css`
                    transform: none;
                `,
            },
        }),
        SpinnerContainer: bemed({
            css: css`
                margin-left: auto;
                margin-right: auto;
                position: absolute;
                top: ${u(24)};
                left: 50%;
                opacity: 0;
                width: ${u(25)};
                padding: ${u(10)} 0 ${u(5)} 0;
                z-index: 102;
                transition: opacity 0.2s ease-out;
            `,
            mods: {
                visible: css`
                    opacity: 1;
                    transition: opacity 0.2s ease-in;
                    transition-delay: 0.2s;
                `,
            },
        }),
        CloseIconContainer: bemed({
            css: css`
                display: flex;
                flex-direction: row;
                position: fixed;
                top: ${u(34)};
                right: ${u(16)};
                z-index: 10000;
                opacity: 0;
                background-color: #fff;
                @media only screen and (min-width: ${u(750)}) {
                    right: ${u(40)};
                }
            `,
            mods: {
                animationFinished: css`
                    opacity: 1;
                    transition: opacity 0.2s ease-in;
                    transition-delay: 0.25s;
                `,
            },
            elements: {
                Link: bemed({
                    as: "a",
                    css: css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        text-decoration: none;
                        color: #000;
                    `,
                }),
                Button: bemed({
                    as: "button",
                    css: css`
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        text-decoration: none;
                        color: #000;
                        padding: 0;
                        border: none;
                        outline: none;
                        font: inherit;
                        color: inherit;
                        background: none;
                    `,
                }),
                Text: bemed({
                    as: "span",
                    css: css``,
                }),
                Icon: bemed({
                    css: css`
                        display: flex;
                    `,
                }),
            },
        }),
        HeaderContainer: bemed({
            css: css`
                top: ${u(0)};
                z-index: 102;
                background-color: white;
                width: 100%;
            `,
        }),
        ScrollBarEqualizer: bemed({
            css: css`
                margin-left: calc(100vw - 100%);
            `,
        }),
        UIWrapper: bemed({
            css: css`
                width: 95%;
                margin: 0 auto;
            `,
        }),
    },
});

function useScrollLock(isActive: boolean) {
    React.useEffect(() => {
        if (!isActive) {
            return;
        }

        const origHeight = window.document.documentElement.style.height;
        const origOverflow = window.document.documentElement.style.overflow;

        window.document.documentElement.style.height = "100%;";
        window.document.documentElement.style.overflow = "hidden";

        return () => {
            window.document.documentElement.style.height = origHeight;
            window.document.documentElement.style.overflow = origOverflow;
        };
    }, [isActive]);
}

export function Main(props: { topMargin: number | string | undefined }) {
    const isSearchActive = useIsSearchActive();
    const { instanceId, setAnimationFinished, animationFinished } =
        useValuSearch();

    useScrollLock(isSearchActive);

    useEffect(() => {
        if (!isSearchActive) {
            setAnimationFinished(false);
        } else {
            const timer = setTimeout(() => {
                setAnimationFinished(true);
            }, 350);
            return () => clearTimeout(timer);
        }
    }, [isSearchActive, setAnimationFinished]);

    let style = {};
    if (props.topMargin !== undefined) {
        style = {
            top: u(props.topMargin),
        };
    }

    return (
        <Blk
            id={"valu-search-container-" + instanceId}
            data-testid={"valu-search-container" as any}
            className={"valu-search-modal valu-search-modal-" + instanceId}
            open={isSearchActive}
            style={style}
        >
            <Blk.Container
                role="search"
                open={isSearchActive}
                animationFinished={animationFinished}
            >
                <Inner />
            </Blk.Container>
        </Blk>
    );
}

function Inner() {
    const isSearchActive = useIsSearchActive();
    const isSearching = useIsSearching();
    const { uiStrings, animationFinished, deactivate } = useValuSearch();

    if (!isSearchActive) {
        // if the search is not active there is no reason to render elements to dom
        // Inner is separated into component so component hiding does not interfere with UI animations
        return <></>;
    }

    return (
        <Blk.ScrollBarEqualizer>
            <Blk.UIWrapper>
                <Slot name="mainWrap" params={{}}>
                    <Slot name="modalHeader" params={{}}>
                        <FullScreenInputWithCloseButton />
                    </Slot>
                    <Blk.SpinnerContainer
                        visible={isSearching}
                        aria-hidden={isSearching ? undefined : "true"}
                    >
                        <Spinner />
                    </Blk.SpinnerContainer>
                    <SearchResultsViewer />
                    <Blk.CloseIconContainer
                        animationFinished={animationFinished}
                    >
                        <Slot name="closeButton" params={{ close: deactivate }}>
                            <Blk.CloseIconContainer.Button
                                aria-label={uiStrings.closeSearch}
                                data-testid="close-button"
                                onClick={deactivate}
                            >
                                <Blk.CloseIconContainer.Text>
                                    {uiStrings.close}
                                </Blk.CloseIconContainer.Text>
                                <Blk.CloseIconContainer.Icon>
                                    <CloseIcon size={32} />
                                </Blk.CloseIconContainer.Icon>
                            </Blk.CloseIconContainer.Button>
                        </Slot>
                    </Blk.CloseIconContainer>
                    <Slot name="valuSearchAd" params={{}}>
                        <ValuSearchAd>Powered by Valu Search</ValuSearchAd>
                    </Slot>
                </Slot>
            </Blk.UIWrapper>
        </Blk.ScrollBarEqualizer>
    );
}
