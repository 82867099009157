import { FocusTrap } from "@valu/focus-trap";
import { useEffect } from "react";
import { useValuSearch } from "..";
import { deprecateLog } from "./helpers";

/**
 * Like document.querySelectorAll() but return real array and ensure
 * HTMLElements only
 */
function selectAll(selector: string) {
    return Array.from(window.document.querySelectorAll(selector)).filter(
        (el): el is HTMLElement => {
            return el instanceof HTMLElement;
        },
    );
}

export function useFocusTrap(enable: boolean) {
    const { instanceId, validateTabbable, inputs, focusTrap } = useValuSearch();

    useEffect(() => {
        if (focusTrap === false) {
            return;
        }

        if (!enable) {
            return;
        }

        const selectors = [
            ".valu-search-focus-trap",
            `.valu-search-focus-trap-${instanceId}`,
        ].join(",");

        const legacySelectors = [
            `#valu-search-container-${instanceId}`,
            `.valu-search-screen-reader-${instanceId}`,
            `.valu-search-focus-trap-container-${instanceId}`,
            `.valu-search-results-viewer-${instanceId}`,
        ].join(",");

        const htmlElements = selectAll(selectors);

        const legacyElements = selectAll(legacySelectors);

        if (legacyElements.length > 0) {
            deprecateLog(
                "Using one of the deprecated focus trap selectors: " +
                    legacySelectors,
            );
        }

        const trapInputs = inputs.filter((input) => {
            for (const container of htmlElements) {
                if (container.contains(input)) {
                    // If input is inside some trap container there's no need to
                    // add it as container again.
                    // XXX: Could do this inside the focus-trap package
                    return false;
                }
            }

            return true;
        });

        if (trapInputs.length === 0) {
            return;
        }

        const trapContainers: HTMLElement[] = [
            ...trapInputs,
            ...htmlElements,
            ...legacyElements,
        ];

        if (trapContainers.length === 0) {
            throw Error(
                `[RVS] No containers found with focus trap selectors: ${selectors}`,
            );
        }

        const trap = new FocusTrap({
            containers: trapContainers,
            validateTabbable: validateTabbable,
        });

        trap.enable();

        return () => {
            trap.disable();
        };
    }, [instanceId, enable, validateTabbable, inputs, focusTrap]);
}
