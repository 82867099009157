import { useValuSearch, FullValuSearchConfig } from "../config";

export function Slot<
    SlotName extends keyof FullValuSearchConfig["slots"],
>(props: {
    name: SlotName;
    params: Parameters<
        NonNullable<FullValuSearchConfig["slots"][SlotName]>
    >[0]["params"];
    children?: React.ReactNode;
}): any {
    const { slots, _config } = useValuSearch();

    const customRenderer = slots[props.name];

    if (!customRenderer) {
        return props.children ?? "";
    }

    return customRenderer({
        params: props.params as any,
        config: _config,
        original: props.children,
    });
}
