import React from "react";
import { getCurrentURLSearchParamsFromWindow } from "./shared-helpers";

const QueryContext = React.createContext<URLSearchParams | null>(null);

function useCurrentURLSearchParams() {
    const [queryValue, setQueryState] = React.useState(new URLSearchParams());

    React.useEffect(() => {
        setQueryState(getCurrentURLSearchParamsFromWindow());
    }, []);

    return [queryValue, setQueryState] as const;
}

export function QueryProvider(props: { children: React.ReactNode }) {
    const [queryValue, setQueryState] = useCurrentURLSearchParams();

    React.useEffect(() => {
        function listen() {
            setQueryState(getCurrentURLSearchParamsFromWindow());
        }

        window.addEventListener("valuSearchHistoryEvent", listen, false);

        return () => {
            window.removeEventListener("valuSearchHistoryEvent", listen, false);
        };
    }, [setQueryState]);

    return (
        <QueryContext.Provider value={queryValue}>
            {props.children}
        </QueryContext.Provider>
    );
}

export function useQueryValue() {
    const context = React.useContext(QueryContext);

    if (typeof context?.toString() !== "string") {
        throw new Error("QueryProvider missing");
    }

    return context;
}
