import { State, SearchResult } from "./state";
import {
    cleanUndefined,
    debug,
    featureFlag,
    isDeepEqual,
} from "../utils/helpers";
import { SearchActions } from "./actions";
import { batch } from "react-redux";
import { EngineGroup } from "../config";
import {
    FindkitFetchOptions,
    SearchGroupParams,
    SearchParams,
    SearchResponse,
} from "@findkit/fetch";

export interface ValuSearchStore {
    getState(): State;
    dispatch(action: { type: string }): void;
    subscribe(cb: Function): () => void;
}

class IdleDispatchStore implements ValuSearchStore {
    private state: State;
    private pendingActions = [] as any[];
    private store: ValuSearchStore;

    constructor(store: ValuSearchStore) {
        this.store = store;
        this.state = store.getState();
    }

    getState() {
        return this.state;
    }

    dispatch(action: any) {
        this.pendingActions.push(action);

        if (this.pendingActions.length > 1) {
            debug("Skipping useless state update", this.pendingActions);
            return;
        }

        const started = performance.now();

        requestIdleCallback(() => {
            const duration = performance.now() - started;
            debug(
                `Deferred ${this.pendingActions.length} Redux dispatches by ${duration}`,
            );

            batch(() => {
                for (const a of this.pendingActions) {
                    this.store.dispatch(a);
                }
                this.pendingActions = [];
            });
        });
    }

    subscribe(cb: () => void) {
        return this.store.subscribe(cb);
    }
}

export interface StaticSearchParams {
    searchEndpoint: string;
    apiKey: string; // XXX is there  a reason for this to be optional ??
}

export interface DynamicSearchParams {
    /**@deprecated use searchLang instead */
    lang: string | undefined;
    searchLang: string[] | undefined;
    terms: string;
    enabled: boolean;
}

export interface EngineSearchGroupParams extends SearchGroupParams {
    id: string;
}
export interface EngineFullSearchParams extends Omit<SearchParams, "groups"> {
    groups: EngineSearchGroupParams[];
}

/**
 * Fethcer is almost like ResultsWithTotal but it does not have the
 * "tagGroupId" property which is added on the client-side
 */
export interface FetcherResponse {
    hits: SearchResult;
    total: number;
}

export interface ValuSearchResponseFetcher {
    (params: FindkitFetchOptions): Promise<SearchResponse[]>;
}

export const searchRequest: ValuSearchResponseFetcher = () => {
    return Promise.resolve([]);
};

function assertInputEvent(e: {
    target?: any;
}): asserts e is { target: HTMLInputElement } {
    if (!(e.target instanceof HTMLInputElement)) {
        throw new Error("Not HTMLInputElement");
    }
}

export interface AddressBarListener {
    listen(cb: (event: { triggerSearch: boolean }) => any): () => void;
    setTerms(terms: string): void;
    setGroupId(id: string): void;
    getTerms(): string;
    getGroupId(): string | undefined;
}

export interface SearchEngineOptions {
    store: ValuSearchStore;
    addressBarListener: AddressBarListener;
    fetcher: ValuSearchResponseFetcher;
    staticSearchParams: StaticSearchParams;
    throttleTime?: number;
    searchMoreSize?: number;
    minSearchTermsLength?: number;
    onSearch: (dynamicSearchParams: DynamicSearchParams) => void;
    onSearchResponse: (options: {
        engineFullSearchParams: EngineFullSearchParams;
        responses: SearchResponse[];
    }) => void;
}

export class SearchEngine {
    store: ValuSearchStore;
    impl: ValuSearchResponseFetcher;
    throttleTime: number;
    throttleTimerID?: ReturnType<typeof setTimeout>;
    addressBarListener: AddressBarListener;
    searchMoreSize: number;
    minSearchTermsLength: number;
    onSearch: (dynamicSearchParams: DynamicSearchParams) => void;
    onSearchResponse: (options: {
        engineFullSearchParams: EngineFullSearchParams;
        responses: SearchResponse[];
    }) => void;
    nextAppendGroupId: string | undefined;
    previousAppendGroupId: string | undefined;
    requestId = 0;
    pendingRequestIds: Set<number> = new Set();
    currentResultsId?: number;
    unbindAddressBarListeners: () => void;

    staticSearchParams: StaticSearchParams;

    nextGroups?: EngineGroup[];
    previousGroups?: EngineGroup[];

    nextSearchParams?: DynamicSearchParams;
    previousSearchParams?: DynamicSearchParams;

    enabled: boolean;

    inputs = [] as {
        input: HTMLInputElement;
        onChange: (e: { target: unknown }) => void;
        onEnter: (e: KeyboardEvent) => void;
    }[];

    constructor(options: SearchEngineOptions) {
        this.enabled = false;
        if (featureFlag("vsExperimentalIdleThrottle")) {
            this.store = new IdleDispatchStore(options.store);
        } else {
            this.store = options.store;
        }

        this._status = options.store.getState().status;

        this.impl = options.fetcher;
        this.addressBarListener = options.addressBarListener;
        this.throttleTime = options.throttleTime ?? 500;
        this.staticSearchParams = options.staticSearchParams;
        this.searchMoreSize = options.searchMoreSize ?? 20;
        this.minSearchTermsLength = options.minSearchTermsLength ?? 2;
        this.onSearch = options.onSearch;
        this.onSearchResponse = options.onSearchResponse;

        const terms = this.addressBarListener.getTerms().trim();

        if (terms) {
            // Initialize with addresbar terms if any. This does not trigger the
            // search because there are no groups at this point yet. Just
            // prepares the engine to make the search with exisiting terms.
            this.api.setSearchParams({
                terms: terms,
            });
        }

        this.unbindAddressBarListeners = this.addressBarListener.listen(
            (event) => {
                if (!event.triggerSearch) {
                    return;
                }

                const terms = this.addressBarListener.getTerms();

                this.api.setSearchParams({
                    terms: terms,
                });

                this.syncInputs(terms ?? "", { force: true });
            },
        );
    }

    subscribe = (cb: Function): (() => void) => {
        return this.store.subscribe(cb);
    };

    getStatus = () => {
        return this._status;
    };

    getState = () => {
        return this.store.getState();
    };

    getTerms = () => {
        return this.addressBarListener.getTerms();
    };

    getGroupResults = (group: string): SearchResult[] => {
        return this.store.getState().results[group]?.hits ?? [];
    };

    /**
     * Only a setStatus() cache
     */
    private _status: State["status"];

    setStatus = (status: State["status"]) => {
        // Avoid updating the store if the state does not change
        if (this._status !== status) {
            this._status = status;
            this.store.dispatch(SearchActions.setStatus(status));
        }
    };

    setErrored = (errored: State["error"]) => {
        this.store.dispatch(SearchActions.setError(errored));
    };

    syncInputs = (terms: string, options?: { force: boolean }) => {
        for (const input of this.inputs) {
            if (options?.force && input) {
                input.input.value = terms;
                continue;
            }
            // only change input value if it does not have focus
            if (input && input.input !== document.activeElement) {
                input.input.value = terms;
            }
        }
    };

    createOnIdleChange(input: HTMLInputElement) {
        let pendingIdle = false;

        return () => {
            if (pendingIdle) {
                debug("Defering input change using request idle callback");
                return;
            }

            const started = performance.now();
            pendingIdle = true;
            requestIdleCallback(() => {
                pendingIdle = false;

                debug(
                    "Deferred setSearchParams() by " +
                        (performance.now() - started),
                );

                this.api.setSearchParams({
                    terms: input.value,
                });
            });
        };
    }

    /**
     * Bind input to search. Returns true when is new input is added. False if
     * the given input was already added
     */
    addInput = (input: HTMLInputElement) => {
        const prev = this.inputs.find((o) => o.input === input);
        if (prev) {
            return false;
        }

        const urlbarTerms = this.addressBarListener.getTerms().trim();

        if (urlbarTerms) {
            // Enable search results linking by copying the terms to the input
            // from url bar but skip if if the input is active so we wont mess
            // with the user too much
            if (input.value.trim() === "" || input !== document.activeElement) {
                input.value = urlbarTerms;
            }
        } else if (input.value.trim()) {
            // Other way around. If user manages to write something to the input
            // before this is called, use that value to make a search. This is
            // mainly for lazy loading when the input can be interacted with
            // before this .addInput() call
            this.api.setSearchParams({ terms: input.value });
        }

        let onChange = (e: { target: unknown }): any => {
            assertInputEvent(e);
            this.api.setSearchParams({
                terms: e.target.value,
            });
        };

        if (featureFlag("vsExperimentalIdleThrottle")) {
            onChange = this.createOnIdleChange(input);
        }

        const onEnter = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                this.api.skipThrottle();
            }
        };

        input.addEventListener("input", onChange);
        input.addEventListener("keydown", onEnter);

        this.inputs.push({ input, onChange, onEnter });

        return true;
    };

    getInputs() {
        return this.inputs.map((o) => o.input);
    }

    removeInput = (rmInput: HTMLInputElement) => {
        const input = this.inputs.find((input) => input?.input === rmInput);

        input?.input.removeEventListener("keydown", input.onEnter);

        input?.input.removeEventListener("change", input.onChange);

        const inputIndex = this.inputs.findIndex((obj) => obj === input);
        this.inputs.splice(inputIndex, 1);
    };

    /**
     * Aka the "from" value for append requests
     */
    getGroupTotal(groupId: string): number {
        return this.store.getState().results[groupId]?.hits.length ?? 0;
    }

    getFullParams = (options: {
        groups: EngineGroup[];
        params: DynamicSearchParams;
        reset: boolean | undefined;
        appendGroupId: string | undefined;
    }) => {
        const groups: EngineSearchGroupParams[] = options.groups
            .filter((group) => {
                if (!options.appendGroupId) {
                    return true;
                }

                return group.id === options.appendGroupId;
            })
            .map((group) => {
                let size = group.size;
                if (options.appendGroupId) {
                    size = this.searchMoreSize;
                }

                let from = 0;
                if (options.appendGroupId && !options.reset) {
                    from = this.getGroupTotal(options.appendGroupId);
                }

                return cleanUndefined({
                    id: group.id,
                    tagQuery: group.filters.tagQuery,
                    createdDecay: group.filters.createdDecay,
                    modifiedDecay: group.filters.modifiedDecay,
                    decayScale: group.filters.decayScale,
                    highlightLength: group.filters.highlightLength,
                    lang: options.params.searchLang
                        ? options.params.searchLang[0]
                        : options.params.lang,
                    size,
                    from,
                });
            });

        const fullParams: EngineFullSearchParams = {
            terms: options.params.terms,
            searchEndpoint: this.staticSearchParams.searchEndpoint,
            apiKey: this.staticSearchParams.apiKey,
            groups,
        };

        return fullParams;
    };

    private updateURLBar = () => {
        const terms = this.previousSearchParams?.terms.trim() ?? "";
        if (this.addressBarListener.getTerms().trim() !== terms) {
            this.addressBarListener.setTerms(terms);
        }
    };

    fetch = (options: { reset?: boolean }) => {
        if (!this.nextGroups || !this.nextSearchParams) {
            return;
        }

        this.setStatus("fetching");
        this.requestId += 1;

        const appendGroupId = this.nextAppendGroupId;
        const id = this.requestId;

        const fullParams = this.getFullParams({
            params: this.nextSearchParams,
            groups: this.nextGroups,
            reset: options.reset,
            appendGroupId: appendGroupId,
        });

        this.setParams({
            previousSearchParams: this.nextSearchParams,
            previousGroups: this.nextGroups,
            nextSearchParams: undefined,
        });

        // We need to sync input values from url bar and if there are multiple
        // search inputs
        this.syncInputs(this.previousSearchParams.terms);

        if (
            this.previousSearchParams.terms.trim().length <
            this.minSearchTermsLength
        ) {
            this.updateURLBar();
            this.fakeEmptyRequestResponse({ id: id, fullParams });
            return;
        }

        this.setParams({ previousAppendGroupId: appendGroupId });

        if (!this.enabled) {
            this.updateURLBar();
            this.setReady();
            return;
        }

        this.pendingRequestIds.add(id);

        // do not emit onSearch events from search more queries
        if (options.reset) {
            this.onSearch(this.previousSearchParams);
        }

        this.updateURLBar();
        this.impl({ ...fullParams }).then(
            (responses) => {
                // there are newer search results already displayed
                if (this.currentResultsId && id < this.currentResultsId) {
                    return;
                }

                // Combine responses with the search groups and re-assign the ids for them
                const resWithIds: State["results"] = {};

                fullParams.groups.forEach((group, index) => {
                    const res = responses[index];
                    if (res) {
                        resWithIds[group.id] = {
                            hits: res.hits.map((hit) => {
                                const { created, modified, ...hitNoDates } =
                                    hit;
                                return {
                                    ...hitNoDates,
                                    created: new Date(created),
                                    modified: new Date(modified),
                                    id: group.id,
                                };
                            }),
                            total: res.total,
                            duration: res.duration,
                        };
                    }
                });

                if (appendGroupId && !options.reset) {
                    this.store.dispatch(
                        SearchActions.addAllResults(resWithIds),
                    );
                } else {
                    this.store.dispatch(
                        SearchActions.setAllResults(resWithIds),
                    );
                }

                this.currentResultsId = id;

                // remove id and all smaller ids from pending requests
                this.pendingRequestIds.forEach((pendingRequestId) => {
                    if (pendingRequestId <= id) {
                        this.pendingRequestIds.delete(pendingRequestId);
                    }
                });

                this.setReady();

                this.onSearchResponse({
                    engineFullSearchParams: fullParams,
                    responses: responses,
                });
            },
            (error) => {
                // remove id from pending requests
                this.pendingRequestIds.delete(id);

                this.setReady();

                this.setErrored({
                    source: "fetch",
                    message:
                        error?.message ||
                        String(error) ||
                        "unknown fetch error",
                });

                console.error("Valu Search fetch error", error, error?.message);
                return;
            },
        );
    };

    private _areParamsEqual?: { cached: boolean };

    areParamsEqual = (): boolean => {
        if (!this._areParamsEqual) {
            this._areParamsEqual = {
                cached:
                    isDeepEqual(
                        this.previousSearchParams,
                        this.nextSearchParams,
                    ) && isDeepEqual(this.previousGroups, this.nextGroups),
            };
        }

        return this._areParamsEqual.cached;
    };

    setParams<T extends Partial<SearchEngine>>(params: T): asserts this is T {
        Object.assign(this, params);
        this._areParamsEqual = undefined;
    }

    triggerFetch = () => {
        if (this.throttleTimerID) {
            return;
        }

        // no new search params OR no next search groups defined
        if (!this.nextSearchParams || !this.nextGroups) {
            this.setReady();
            return;
        }

        const isEqual = this.areParamsEqual();

        // stop search more if everyhing has been fetched
        if (!this.canFetchMore() && this.nextAppendGroupId && isEqual) {
            this.setParams({
                nextSearchParams: undefined,
            });
            this.setReady();
            return;
        }

        // this is ignored in group details OR if the last search was in group details
        if (isEqual && !this.nextAppendGroupId && !this.previousAppendGroupId) {
            // stop sending requests in groupped preview if
            // user changed search terms during throttle and
            // they are the same as last search params

            // Clear the nextFetchParams since they were equal with previous
            // one, so there's nothing to do with them. Also mark as ready if
            // there are no other requests in flight.
            this.setParams({
                nextSearchParams: undefined,
            });
            this.setReady();
            return;
        }

        this.fetch({ reset: !isEqual });

        this.throttleTimerID = setTimeout(() => {
            this.throttleTimerID = undefined;
            this.triggerFetch();
        }, this.throttleTime);
    };

    enable = () => {
        if (!this.enabled) {
            this.enabled = true;
            // When the engine is in disabled state the groups are rotated but
            // not used so we need to restore the previous groups to actually
            // trigger fetch (ie. ensure the isEqual check return false in
            // triggerFetch())
            //
            // Next params can be set before first search which rotates them
            // to previous -> prioritize next search params, this should matter
            // only in test cases. ex. search-engine.test.ts -->
            // ".setGroups() triggers only one search"
            if (!this.nextSearchParams && this.previousSearchParams) {
                this.nextSearchParams = {
                    ...this.previousSearchParams,
                    enabled: true,
                };
                this.previousSearchParams = undefined;
            } else if (this.nextSearchParams) {
                this.nextSearchParams = {
                    ...this.nextSearchParams,
                    enabled: true,
                };
            }
            this.triggerFetch();
        }
    };

    disable = () => {
        this.enabled = false;
    };

    fakeEmptyRequestResponse = (options: {
        id: number;
        fullParams: EngineFullSearchParams;
    }) => {
        // there are newer search results already displayed
        if (this.currentResultsId && options.id < this.currentResultsId) {
            return;
        }

        // Combine responses with the search groups and re-assign the ids for them
        const resWithIds: State["results"] = {};

        options.fullParams.groups.forEach((group) => {
            resWithIds[group.id] = {
                hits: [],
                total: 0,
                duration: 0,
            };
        });

        this.store.dispatch(SearchActions.setAllResults(resWithIds));

        this.currentResultsId = options.id;

        this.pendingRequestIds.clear();

        this.setReady();
    };

    setReady = () => {
        // if there are any in flight requests OR pending requests do not set ready
        if (this.pendingRequestIds.size === 0 && !this.nextSearchParams) {
            this.setStatus("ready");
        }
    };

    setGroups = (groups: EngineGroup[]) => {
        const isEqual = isDeepEqual(groups, this.previousGroups);
        // Faulty provider configuration sends setGroups on each react render
        // don't set params if the groups are equal to old ones
        if (isEqual) {
            return;
        }

        // If no next search params, just re use the previous ones to trigger
        // the search with the new groups
        this.setParams({
            nextGroups: groups,
            nextAppendGroupId: this.getCurrentGroupId(),
            nextSearchParams:
                this.nextSearchParams ?? this.previousSearchParams,
        });

        if (this.throttleTimerID) {
            return;
        }

        this.api.skipThrottle();
    };

    canFetchMore = () => {
        // only usable in "search more" state
        if (this.nextAppendGroupId === undefined) {
            return false;
        }

        const groupState =
            this.store.getState().results[this.nextAppendGroupId];

        if (!groupState) {
            return false;
        }

        return groupState.hits.length < groupState.total;
    };

    /**
     * Get group id from the address bar if it is an existing group
     */
    private getCurrentGroupId(): string | undefined {
        const groups = this.nextGroups || this.previousGroups;
        if (!groups) {
            return;
        }

        // When using only one group we can just use the id of the first group
        if (groups.length === 1 && groups[0]) {
            return groups[0].id;
        }

        const id = this.addressBarListener.getGroupId();
        return groups.find((group) => group.id === id)?.id;
    }

    api = {
        skipThrottle: () => {
            if (this.throttleTimerID) {
                clearTimeout(this.throttleTimerID);
                this.throttleTimerID = undefined;
            }

            this.fetch({ reset: true });
        },

        searchMore: () => {
            this.api.setSearchParams({ ...this.nextSearchParams });
        },

        setSearchParams: (params: Partial<DynamicSearchParams>) => {
            const nextParams: DynamicSearchParams = {
                terms: "",
                lang: undefined,
                searchLang: undefined,
                ...this.previousSearchParams,
                ...this.nextSearchParams,
                ...params,
                enabled: this.enabled,
            };

            this.setParams({
                nextSearchParams: nextParams,
                nextAppendGroupId: this.getCurrentGroupId(),
            });

            if (this.throttleTimerID) {
                return;
            }

            this.triggerFetch();
        },
    };
}
