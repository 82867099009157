import React from "react";
import { css } from "react-bemed/css";
import { useGroupSearchResults, useIsSearching } from "../redux/hooks";
import { useSearchTerms } from "../utils/use-search-terms";
import ResultList from "./ResultList";
import {
    assertNonNullable,
    u,
    useGetInnerLinkFromWindow,
} from "../utils/helpers";
import { useValuSearch } from "../config";
import { GroupIdProvider, useGroup, useOrderedGroups } from "./GroupInfo";
import { bemed } from "./bemed";
import { ValuSearchLink } from "./SharedComponents";
import { Slot } from "./Slot";
import { ArrowRightIcon } from "./ReactIcons";

const Blk = bemed({
    name: "GrouppedResultPreview",

    css: css`
        width: 100%;
        backface-visibility: hidden;
        transform: translateZ(0);
        -webkit-font-smoothing: subpixel-antialiased;
    `,

    mods: {
        opaque: css`
            opacity: 0.55;
        `,
    },
});

const GroupContainer = bemed({
    name: "SearchGroupContainer",

    css: css`
        padding-top: ${u(8)};
        padding-bottom: ${u(32)};
    `,

    mods: {
        opaque: css`
            opacity: 0.55;
        `,
    },
});

export const GroupLinkContainer = bemed({
    name: "GroupLinkContainer",

    css: css`
        display: flex;
        flex-direction: row;
        align-items: center;
        height: ${u(36)};
        z-index: 100;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: ${u(750)};

        a {
            text-decoration: none;

            h3 {
                border-bottom: solid ${u(2)} transparent;
                :hover {
                    border-bottom: solid ${u(2)} #000;
                }
            }
        }
    `,

    elements: {
        Link: bemed({
            as: "a",
            css: css`
                text-decoration: none;
                :hover {
                    text-decoration: underline;
                }
            `,
        }),
        LinkTitle: bemed({
            as: "h3",
            css: css`
                flex-direction: row;
                align-items: center;
                display: flex;
                text-decoration: none;
                color: #000;
                transition: all 0.1s ease;
            `,
        }),
        ArrowIcon: bemed({
            css: css`
                display: flex;
                flex-direction: row;
                margin: ${u(1)} ${u(-7)} auto ${u(6)};
            `,
            mods: {
                forward: css``,
                back: css`
                    margin-top: ${u(0)};
                    margin-left: ${u(-8)};
                    margin-right: ${u(0)};
                `,
            },
        }),
    },
});

function MoreLink(props: { q: string }) {
    const group = useGroup();
    assertNonNullable(group, "group missing in MoreLink");

    const currentResults = useGroupSearchResults(group.id);
    const getInnerLinkFromWindow = useGetInnerLinkFromWindow();
    const { uiStrings, events } = useValuSearch();
    const terms = useSearchTerms();

    let more: string;
    if (currentResults.total === 0) {
        more = "none";
    } else if (currentResults.hits.length < currentResults.total) {
        more = "more";
    } else {
        more = "nomore";
    }

    switch (more) {
        case "more":
            return (
                <ValuSearchLink
                    href={getInnerLinkFromWindow({
                        searchTerms: props.q,
                        groupId: group.id,
                    })}
                    dataTestId="all-group-results-link"
                    onClick={() =>
                        events.emit({
                            name: "group-results-clicked",
                            terms: terms,
                            groupTitle: group.title || "",
                        })
                    }
                >
                    <GroupLinkContainer.LinkTitle
                        aria-label={
                            uiStrings.moreResults +
                            " " +
                            uiStrings.moreResultsIn +
                            " " +
                            group.title
                        }
                    >
                        <Slot
                            name="groupLinkTitleWrap"
                            params={{
                                groupTitle: group.title || "",
                                ariaLabel:
                                    uiStrings.moreResults +
                                    " " +
                                    uiStrings.moreResultsIn +
                                    " " +
                                    group.title,
                                type: "more",
                            }}
                        >
                            {uiStrings.moreResults}
                        </Slot>
                        <GroupLinkContainer.ArrowIcon forward>
                            <ArrowRightIcon size={24} />
                        </GroupLinkContainer.ArrowIcon>
                    </GroupLinkContainer.LinkTitle>
                </ValuSearchLink>
            );
        case "nomore":
            return (
                <GroupLinkContainer.LinkTitle
                    aria-label={
                        uiStrings.allShown +
                        " " +
                        uiStrings.moreResultsIn +
                        " " +
                        group.title
                    }
                >
                    <Slot
                        name="groupLinkTitleWrap"
                        params={{
                            groupTitle: group.title || "",
                            ariaLabel:
                                uiStrings.allShown +
                                " " +
                                uiStrings.moreResultsIn +
                                " " +
                                group.title,
                            type: "allShown",
                        }}
                    >
                        {uiStrings.allShown}
                    </Slot>
                </GroupLinkContainer.LinkTitle>
            );
        case "none":
            return (
                <GroupLinkContainer.LinkTitle
                    aria-label={
                        uiStrings.noResults +
                        " " +
                        uiStrings.moreResultsIn +
                        " " +
                        group.title
                    }
                >
                    <Slot
                        name="groupLinkTitleWrap"
                        params={{
                            groupTitle: group.title || "",
                            ariaLabel:
                                uiStrings.noResults +
                                " " +
                                uiStrings.moreResultsIn +
                                " " +
                                group.title,
                            type: "noResults",
                        }}
                    >
                        {uiStrings.noResults}
                    </Slot>
                </GroupLinkContainer.LinkTitle>
            );
        default:
            return null;
    }
}

export function GrouppedResultPreview() {
    const { previewGroupSize } = useValuSearch();
    const isPending = useIsSearching();
    const terms = useSearchTerms();
    const orderedGroups = useOrderedGroups();

    return (
        <Blk opaque={isPending}>
            {orderedGroups.map((group) => {
                return (
                    <Slot
                        key={group.title}
                        name="groupPreview"
                        params={{
                            title: group.title,
                            total: group.groupResults.total,
                            hits: group.groupResults.hits,
                        }}
                    >
                        <GroupIdProvider key={group.title} id={group.id}>
                            <GroupContainer
                                opaque={group.groupResults.hits.length === 0}
                            >
                                <ResultList
                                    id={group.id}
                                    title={group.title}
                                    results={group.groupResults}
                                    limitResultCount={previewGroupSize ?? 5}
                                />
                                <GroupLinkContainer>
                                    <MoreLink q={terms} />
                                </GroupLinkContainer>
                            </GroupContainer>
                        </GroupIdProvider>
                    </Slot>
                );
            })}
        </Blk>
    );
}
