import React from "react";
import { css } from "react-bemed/css";
import { SearchResult } from "../redux/state";
import { u } from "../utils/helpers";
import { Slot } from "./Slot";
import { Hit } from "./SharedComponents";
import { bemed } from "./bemed";
import { useValuSearch } from "../config";
import { HitContextProvider } from "./HitInfo";

const JSXContainer = bemed({
    name: "GroupResults",

    css: css`
        margin: 0 auto;
        z-index: 100;
        width: 100%;
        max-width: ${u(750)};
    `,
});

const HitsContainer = bemed({
    name: "HitsContainer",

    css: css`
        display: block;
        padding-bottom: ${u(24)};
        z-index: 100;
    `,

    elements: {
        Button: bemed({
            as: "button",
            className: "reset-button",

            defaultProps: {
                type: "button",
            },
            css: css`
                display: flex;
                flex-direction: row;
                justify-content: center;
                border: none;
                height: ${u(65)};
                background: white;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                @media (min-width: ${u(768)}) {
                    width: ${u(511)};
                }
            `,
        }),
    },
});

const HitsInfo = bemed({
    name: "HitsInfo",

    css: css`
        display: flex;
        flex-direction: row;
        border-bottom-style: solid;
        border-width: 0 0 ${u(1)} 0;
        border-image: linear-gradient(90deg, #595959, transparent) 1;
    `,

    elements: {
        ResultTitle: bemed({
            as: "h2",
            css: css`
                padding-right: ${u(12)};
                margin: ${u(12)} ${u(0)};
                font-size: ${u(22)};
                @media only screen and (min-width: ${u(750)}) {
                    margin: ${u(20)} ${u(0)};
                    font-size: ${u(24)};
                }
            `,
        }),
        ResultsTotal: bemed({
            as: "h2",
            css: css`
                margin: ${u(12)} ${u(0)};
                font-size: ${u(22)};
                @media only screen and (min-width: ${u(750)}) {
                    margin: ${u(20)} ${u(0)};
                    font-size: ${u(24)};
                }
            `,
        }),
    },
});

const emptyResult = {
    hits: [],
};

export const getResultsTotal = (total: number | undefined) => {
    if (typeof total === "undefined") {
        return "0";
    }

    return total >= 10000 ? "10k+" : total.toString();
};

export default function ResultList(props: {
    title: string;
    id: string;
    limitResultCount?: number;
    results: {
        hits: SearchResult[];
        total: number;
        duration?: number;
    };
}) {
    let hits = (props.results || emptyResult).hits;
    const { uiStrings, instanceId } = useValuSearch();

    if (props.limitResultCount) {
        hits = hits.slice(0, props.limitResultCount);
    }

    return (
        <JSXContainer>
            <HitsInfo>
                <Slot
                    name="groupTitle"
                    params={{
                        id: props.id,
                        title: props.title,
                        ariaLabel:
                            uiStrings.searchResultGroup + " " + props.title,
                    }}
                >
                    <HitsInfo.ResultTitle
                        data-testid="results-group-title"
                        className={`valu-search-screen-reader-${instanceId}`}
                        aria-label={
                            uiStrings.searchResultGroup + " " + props.title
                        }
                        data-group-search-duration={props.results.duration}
                    >
                        {props.title}
                    </HitsInfo.ResultTitle>
                </Slot>
                <Slot
                    name="groupTotal"
                    params={{
                        id: props.id,
                        total: getResultsTotal(props.results.total),
                        ariaLabel:
                            uiStrings.searchResults +
                            getResultsTotal(props.results.total),
                    }}
                >
                    <HitsInfo.ResultsTotal
                        data-testid="results-group-total"
                        className={`valu-search-screen-reader-${instanceId}`}
                        aria-label={
                            uiStrings.searchResults +
                            " " +
                            getResultsTotal(props.results.total)
                        }
                    >
                        {"(" + getResultsTotal(props.results.total) + ")"}
                    </HitsInfo.ResultsTotal>
                </Slot>
            </HitsInfo>

            <HitsContainer>
                {hits.map((hit) => {
                    return (
                        <HitContextProvider key={hit.url} hit={hit}>
                            <Slot
                                name="hit"
                                key={hit.url}
                                params={{
                                    tagGroupId: hit.id,
                                    id: hit.id,
                                    title: hit.title,
                                    highlight: hit.highlight,
                                    url: hit.url,
                                    created: hit.created,
                                    score: hit.score,
                                    modified: hit.modified,
                                    customFields: hit.customFields,
                                }}
                            >
                                <Hit />
                            </Slot>
                        </HitContextProvider>
                    );
                })}
            </HitsContainer>
        </JSXContainer>
    );
}
