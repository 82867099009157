import { useSelector, useStore } from "react-redux";
import { SearchResult, State } from "./state";

export function useSelectSearchState<T>(selector: (state: State) => T): T {
    return useSelector(selector);
}

export interface SearchResults {
    [groupId: string]: {
        hits: SearchResult[];
        total: number;
        duration?: number | undefined;
    };
}

export function useSearchResults(): SearchResults {
    return useSelectSearchState((state) => state.results);
}

export function useIsSearching() {
    return useSelectSearchState((state) => state.status === "fetching");
}

export function useGroupSearchResults(id: string) {
    return useSelectSearchState((state) => {
        const groupResults = state.results[id];

        if (!groupResults) {
            return {
                hits: [],
                total: 0,
                duration: 0,
            };
        }

        return groupResults;
    });
}

export function useSearchStore() {
    return useStore<State>();
}
