import { useGroupSearchResults } from "../redux/hooks";
import { useSearchTerms } from "../utils/use-search-terms";
import ResultList from "./ResultList";
import { css } from "react-bemed/css";
import {
    assertNonNullable,
    u,
    useGetInnerLinkFromWindow,
} from "../utils/helpers";
import { useValuSearch } from "../config";
import { Slot } from "./Slot";
import { bemed } from "./bemed";
import { GroupLinkContainer } from "./GrouppedResultPreview";
import { ValuSearchLink } from "./SharedComponents";
import { ArrowRightIcon, ArrowLeftIcon } from "./ReactIcons";
import React from "react";
import { useGroup, useHasSingleGroup } from "./GroupInfo";
import { useIntersectionObserver } from "../utils/intersection-observer-hook";

const Blk = bemed({
    name: "Container",

    css: css`
        display: block;
        width: 100%;
    `,

    elements: {
        Header: bemed({
            css: css`
                position: sticky;
                position: -webkit-sticky;
                top: ${u(112)};
                background-color: white;
                padding-bottom: ${u(8)};
                z-index: 100;
            `,
        }),
        Scrollable: bemed({
            css: css`
                z-index: 99;
            `,
        }),
    },
});

const GroupResultsButton = bemed({
    name: "GroupResultsButton",
    as: "button",
    className: "reset-button",

    css: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 100;
        width: 100%;
        max-width: ${u(750)};
        padding: 0;
        border: none;
        outline: none;
        font: inherit;
        color: inherit;
        background: none;
        cursor: pointer;
    `,

    elements: {
        ButtonTitle: bemed({
            as: "h3",
            css: css`
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 0;
            `,
        }),
        ArrowIcon: bemed({
            css: css`
                display: flex;
                flex-direction: row;
                margin: auto ${u(0)} auto ${u(15)};
            `,
        }),
    },
});

function MoreLink(props: {
    more: "more" | "nomore" | undefined;
    onSearchMore: () => void;
}) {
    const { uiStrings, infiniteScroll } = useValuSearch();

    const ref = useIntersectionObserver(
        () => {
            props.onSearchMore();
        },
        { active: infiniteScroll },
    );

    switch (props.more) {
        case "more":
            return (
                <GroupResultsButton ref={ref} onClick={props.onSearchMore}>
                    <GroupResultsButton.ButtonTitle>
                        {uiStrings.moreResults}
                        <GroupResultsButton.ArrowIcon>
                            <ArrowRightIcon size={24} />
                        </GroupResultsButton.ArrowIcon>
                    </GroupResultsButton.ButtonTitle>
                </GroupResultsButton>
            );
        case "nomore":
            return (
                <GroupLinkContainer.LinkTitle data-testid="all-results-shown-message">
                    {uiStrings.allShown}
                </GroupLinkContainer.LinkTitle>
            );
        default:
            return null;
    }
}

export function GroupResults() {
    const { uiStrings, searchMore, events } = useValuSearch();
    const group = useGroup();
    const single = useHasSingleGroup();
    assertNonNullable(group, "group missing in GroupResults");
    const currentResults = useGroupSearchResults(group.id);
    const getInnerLinkFromWindow = useGetInnerLinkFromWindow();
    const terms = useSearchTerms();

    const currentResultsWithTagGroupId = {
        total: currentResults.total,
        hits: currentResults.hits,
        duration: currentResults.duration,
    };

    const fetchedAllResults =
        currentResults.hits.length >= currentResults.total;

    const more = fetchedAllResults ? "nomore" : "more";

    return (
        <Blk data-testid="group-result-scroll-container">
            <Slot name="groupResultsHeaderWrap" params={{}}>
                {!single && (
                    <Blk.Header>
                        <GroupLinkContainer>
                            <ValuSearchLink
                                href={getInnerLinkFromWindow()}
                                dataTestId="back-to-preview-link"
                                onClick={() =>
                                    events.emit({
                                        name: "back-button-clicked",
                                        terms: terms,
                                        groupTitle: group.title,
                                    })
                                }
                            >
                                <GroupLinkContainer.LinkTitle>
                                    <GroupLinkContainer.ArrowIcon back>
                                        <ArrowLeftIcon size={24} />
                                    </GroupLinkContainer.ArrowIcon>
                                    {uiStrings.back}
                                </GroupLinkContainer.LinkTitle>
                            </ValuSearchLink>
                        </GroupLinkContainer>
                    </Blk.Header>
                )}
            </Slot>

            <Slot name="groupResultsWrap" params={{}}>
                <Blk.Scrollable>
                    <ResultList
                        id={group.id}
                        title={group.title}
                        results={currentResultsWithTagGroupId}
                    />
                    <Slot
                        name="searchMore"
                        params={{
                            searchMore: searchMore,
                            hasMore: !fetchedAllResults,
                        }}
                    >
                        <GroupLinkContainer>
                            <MoreLink
                                more={more}
                                onSearchMore={() => searchMore()}
                            />
                        </GroupLinkContainer>
                    </Slot>
                </Blk.Scrollable>
            </Slot>
        </Blk>
    );
}
